import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import '../style/Timeline.css';
import { fetchTimelineData } from '../../../store/timelineSlice';


function Timeline() {
    const dispatch = useDispatch();
    const timelineData = useSelector(state => state.timeline.data);
    const newItems = useSelector(state => state.timeline.newItems);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const filteredTimelineData = timelineData.filter(
        item => item.history_network
    );



    useEffect(() => {
        dispatch(fetchTimelineData());

        const intervalId = setInterval(() => {
            dispatch(fetchTimelineData());
        }, 30000);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    return (
        <div className="earnfeed">
            <div className="timeline_earn" id="timeline">
                {filteredTimelineData.map((item, index) => {
                    const isNewItem = newItems.some(newItem => newItem.history_timecreated === item.history_timecreated);
                    return (
                        <div
                            className={`timeline_item ${hoveredIndex === index ? 'hovered' : ''} ${isNewItem ? 'new-item' : ''}`}
                            key={index}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="timeline_item_left">
                                <div className="avatar">
                                    <img
                                        src={
                                            item.history_member_avatar && item.history_member_avatar !== ""
                                                ? item.history_member_avatar
                                                : `https://eu.ui-avatars.com/api/?background=random&name=${item.history_membername}?`
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="timeline_item_right">
                                <div className="notify">
                                    <div className="username">
                                        <span>{item.history_membername}</span>
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="info_coins">
                                        <div className="coins_icon">
                                            <img
                                                src="/assets/images/buzz_coin.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="coins_number">
                                            <span>{item.history_amount}</span>
                                        </div>
                                    </div>
                                    <div className="info_network">
                                        <span>{item.history_network}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tooltip ${hoveredIndex === index ? 'show' : ''}`}
                                style={{
                                    width: `192px`,
                                    position: 'absolute',
                                    left: `100px + calc(220px * ${index})`,
                                    top: '230px',
                                    display: hoveredIndex === index ? 'block' : 'none',
                                    zIndex: '9999',
                                }}
                            >
                                <div className="tooltip-inner">
                                    <div className="tooltip-table">
                                        <div className="tooltip-row">
                                            <span className='tooltip-caption'>Offer:</span>
                                            <span className="tooltip-value">{item.history_offername}</span>
                                        </div>
                                        <div className="tooltip-row">
                                            <span className='tooltip-caption'>Time:</span>
                                            <span className="tooltip-value">  {moment(item.history_timecreated).startOf('minute').fromNow()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Timeline;
