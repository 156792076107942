import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Toast from './Toast';
import { Level, Exp } from './LevelAndExp';
import { updateDisplayName } from '../../../store/userSlice';
import { Line } from 'react-chartjs-2';
import '../style/ProfileModal.css'

function TableHeader() {
    return (
        <thead>
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Status</th>
                <th>Network</th>
                <th>Time Created</th>
                <th>Amount</th>
            </tr>
        </thead>
    );
}

function TableBody({ data }) {
    return (
        <tbody>
            {data.map((item, index) => (
                <tr key={index}>
                    <td>{item.transaction_type__name}</td>
                    <td>{item.task_name}</td>
                    <td>{item.task_status}</td>
                    <td>{item.network}</td>
                    <td>{moment(item.timecreated).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{item.amount}</td>
                </tr>
            ))}
        </tbody>
    );
}

function ProfileModal({ isOpen, onClose }) {
    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState('All');
    const [isEditing, setIsEditing] = useState(false);
    const [editedDisplayName, setEditedDisplayName] = useState(user.display_name);
    const formattedJoinDate = moment(user.date_joined).fromNow();
    const [toasts, setToasts] = useState([]);
    const token = localStorage.getItem('userData');
    const [userHistory, setUserHistory] = useState([]);

    useEffect(() => {
        axios
            .get('https://app.cashbuzz.net/api/get-user-history', {
                headers: {
                    
                    Authorization: `Bearer ${JSON.parse(token).access_token}`,
                    
                },
            })
            .then((response) => {
                setUserHistory(response.data.all_history);
            })
            .catch((error) => {
                console.error('Error fetching user history from API', error);
            });
    }, []);

    const filterHistoryByType = () => {
        switch (currentTab.toLowerCase()) {
            case 'all':
                return userHistory;
            case 'credited':
                return userHistory.filter(item => item.transaction_type__name.toLowerCase() === 'complete');
            case 'pending':
                return userHistory.filter(item => item.transaction_type__name.toLowerCase() === 'pending');
            case 'chargeback':
                return userHistory.filter(item => item.transaction_type__name.toLowerCase() === 'chargeback');
            case 'withdraw':
                return userHistory.filter(item => item.transaction_type__name.toLowerCase() === 'withdraw');
            default:
                return userHistory.filter(item => item.transaction_type__name.toLowerCase() === currentTab.toLowerCase());
        }
    }

    const handleVerifyEmail = async () => {
        try {
            const response = await axios.post("https://app.cashbuzz.net/api/email-verify", {}, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token).access_token}`,
                },
            });

            if (response.status === 200) {
                showToast('success', 'A verification email has been sent. Please check your inbox.');
            } else {
                showToast('error', 'Error occurred while requesting email verification!');
            }
        } catch (error) {
            showToast('error', 'Error occurred while sending email verification request!');
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);

        axios
            .put(
                'https://app.cashbuzz.net/api/update-user-info',
                { display_name: editedDisplayName },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(token).access_token}`,
                       
                    },
                }
            )
            .then((response) => {
                console.log('Successfully updated');
                dispatch(updateDisplayName(editedDisplayName));
                showToast('success', 'Successfully updated');
            })
            .catch((error) => {
                console.error('Error updating username', error);
                showToast('error', 'Error updating username');
            });
    };

    const showToast = (type, message) => {
        const id = Math.random().toString(36).substr(2, 9);
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };

    const processEarningsData = (history) => {
        const endDay = moment();
        const startDay = moment().subtract(6, 'days');
        const days = [];
        for (let m = moment(startDay); m.diff(endDay, 'days') <= 0; m.add(1, 'days')) {
            days.push(m.format('YYYY-MM-DD'));
        }

        const dataByDay = days.map(day => {
            const dailyAmount = history
                .filter(item => moment(item.timecreated).isSame(moment(day), 'day'))
                .reduce((acc, curr) => acc + curr.amount, 0);
            return dailyAmount;
        });

        return {
            labels: days,
            datasets: [{
                label: 'Earnings',
                data: dataByDay,
                fill: false,
                borderColor: '#2A292F',
                tension: 0.1
            }]
        };
    };

    return (
        <div className={`profile-modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <div className='profile_header'>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>

                <div className='main_info'>
                    <div className='user_avatar'>
                        {user && user.avatar && (
                            <img src={user.avatar} alt="User Avatar" className="user-avatar" />
                        )}
                    </div>
                    <div className='detail_info'>
                        <div className='display_info'>
                            {isEditing ? (
                                <div className='user_display_name'>
                                    <input
                                        type="text"
                                        value={editedDisplayName}
                                        onChange={(e) => setEditedDisplayName(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <div className='user_display_name'>{editedDisplayName}</div>
                            )}
                            {isEditing ? (
                                <div onClick={handleSaveClick}>
                                    <ion-icon name="checkmark-done-outline"></ion-icon>
                                </div>
                            ) : (
                                <div onClick={handleEditClick}>
                                    <ion-icon name="create-outline"></ion-icon>
                                </div>
                            )}
                            <Level userExp={user.user_exp} />
                        </div>
                        <div className='detail_display_info'>
                            <div className='join_date'>
                                <span>Join date: </span><span>{formattedJoinDate}</span>
                            </div>
                            <Exp userExp={user.user_exp} />
                            <div className='email'>
                                <div className='email_left'>
                                    <span>Email:  </span><span>{user.email} </span>
                                </div>
                                <div className='email_right'>
                                    {user.user_verify ? (
                                        <span className='verify'>
                                            <ion-icon name="checkmark-done-circle"></ion-icon>
                                        </span>
                                    ) : (
                                        <div className='btn_verify' onClick={handleVerifyEmail} id="btn_verify">
                                            <span>Verify your email</span>
                                            
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub_info'>
                    <div className='user_uid'>
                        <div className='user_uid_title'>
                            <span><ion-icon name="finger-print"></ion-icon></span>
                            <span>ID</span>
                        </div>
                        <div className='user_uid_info'>
                            <span>{user.user_uid}</span>
                        </div>
                    </div>
                </div>

                <div className='user_statis'>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                            <span>{user.offers_completed}</span>
                        </div>
                        <div className='statis_text'>
                            <span>Offers Completed</span>
                        </div>
                    </div>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                            <span>{user.coins_earned}</span>
                        </div>
                        <div className='statis_text'>
                            <span>Coins Earned</span>
                        </div>
                    </div>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                            <span>{user.user_coins}</span>
                        </div>
                        <div className='statis_text'>
                            <span>Balance</span>
                        </div>
                    </div>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                        <span>{user.invite_by ? user.invite_by : "Nobody 😞"}</span>
                        
                        </div>
                        <div className='statis_text'>
                        <span>Referee</span>
                        </div>
                    </div>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                            <span>{user.referralearnings}</span>
                        </div>
                        <div className='statis_text'>
                            <span>Referral Earnings</span>
                        </div>
                    </div>
                    <div className='statis_card'>
                        <div className='statis_numb'>
                            <span>{user.users_invited}</span>
                        </div>
                        <div className='statis_text'>
                            <span>Users Invited</span>
                        </div>
                    </div>
                </div>
                <div className='user_chart'>
                    <div className='chart_title'>
                        <span>Last 7 days earnings</span>
                    </div>
                    <div className='chart_detail'>
                        {userHistory && userHistory.length > 0 && (
                            <Line data={processEarningsData(userHistory)} />
                        )}
                    </div>
                </div>

                <div className='user_history'>
                    <div className="tabs">
                        <div
                            className={`tab_items ${currentTab === 'All' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('All')}
                        >
                            All
                        </div>
                        <div
                            className={`tab_items ${currentTab === 'Credited' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('Credited')}
                        >
                            Credited
                        </div>
                        <div
                            className={`tab_items ${currentTab === 'Pending' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('Pending')}
                        >
                            Pending
                        </div>
                        <div
                            className={`tab_items ${currentTab === 'Chargeback' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('Chargeback')}
                        >
                            Chargeback
                        </div>
                        <div
                            className={`tab_items ${currentTab === 'Withdraw' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('Withdraw')}
                        >
                            Withdraw
                        </div>
                    </div>

                    <div className="tab-content">
                        <table>
                            <TableHeader />
                            <TableBody data={filterHistoryByType()} />
                        </table>
                    </div>
                </div>
            </div>

            <div className="notifications">
                {toasts.map((toast, index) => (
                    <Toast
                        key={index}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
        </div>
    );
}

export default ProfileModal;
