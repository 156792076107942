import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import GiftWithdraw from "../assets/GiftWithdraw ";
import Toast from '../assets/Toast';
import '../style/GiftWithdraw.css';

function GiftSection() {
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [toasts, setToasts] = useState([]);
    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };
    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };
    const handleMethodClick = (method) => {
        if (!isUserLogin) {
            showToast('error', 'To proceed with this action, you are required to log in.');
            return;
        }
        setSelectedMethod(method);
        setIsModalOpen(true);
    };


    return (
        <>
            <div className="withdraw_cash_list">
                <div className="method_list">
                    <div className="card_method paypal" onClick={() => handleMethodClick('paypal')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_paypal.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method amazon" onClick={() => handleMethodClick('amazon')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_amazon.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method apple" onClick={() => handleMethodClick('apple')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_apple.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method google" onClick={() => handleMethodClick('google')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_google.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method steam" onClick={() => handleMethodClick('steam')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_steam.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method netflix" onClick={() => handleMethodClick('netflix')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_netflix.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method spotify" onClick={() => handleMethodClick('spotify')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_spotify.png"} alt='' />
                        </div>
                    </div>


                    <div className="card_method nintendo" onClick={() => handleMethodClick('nintendo')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_nintendo.png"} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="notifications">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
            <GiftWithdraw isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} selectedMethod={selectedMethod} />
        </>
    )
}
export default GiftSection;