import '../weblead/webapp.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



function Webclicker() {

    const [hasMemberId, setHasMemberId] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const memberId = localStorage.getItem('member_id');
        if (memberId) {
            setHasMemberId(true);
        } else {
            // Fetch IP and Platform information, then make the API call
            axios.get('https://api.ipify.org?format=json')
                .then(response => {
                    const ip = response.data.ip;
                    const platform = navigator.userAgent.toLowerCase().includes('android') ? 'Android'
                        : navigator.userAgent.toLowerCase().includes('iphone') || navigator.userAgent.toLowerCase().includes('ipad') ? 'iOS'
                            : 'Desktop';

                    return axios.post('https://app.cashbuzz.net/api/webclicker', { ip, platform });
                })
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('member_id', response.data);
                        setHasMemberId(true);
                        navigate('/webclicker');
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    }, []);


    return (
        <div className='webbody'>
            <div className="mainheader">
                <div className="topheader">
                    <div className="left-menu-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <a className="logo">
                        <img src="/assets/images/topbar_logo.png" alt="" />
                    </a>


                </div>

            </div>

            <div className="webhome-page">
                <div className="checker-container">
                    <div className="checker">
                        <img src="/assets/weblead/check.png" alt="" />
                        <p>Complete Clicker Registration</p>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Webclicker;