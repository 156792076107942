
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import GiftWithdraw from "../assets/GiftWithdraw ";
import '../style/GiftWithdraw.css'
import Toast from '../assets/Toast';

function GameSection() {
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [toasts, setToasts] = useState([]);
    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };
    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };
    const handleMethodClick = (method) => {
        if (!isUserLogin) {
            showToast('error', 'To proceed with this action, you are required to log in.');
            return;
        }
        setSelectedMethod(method);
        setIsModalOpen(true);
    };
    return (
        <>

            <div className="withdraw_cash_list">
                <div className="method_list">
                    <div className="card_method roblox" onClick={() => handleMethodClick('roblox')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_roblox.png"} alt='' />
                        </div>
                    </div>

                </div>
            </div>
            <div className="notifications">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
            <GiftWithdraw isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} selectedMethod={selectedMethod} />

        </>
    )
}
export default GameSection;