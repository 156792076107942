import './style/Community.css'
import React, { useEffect, useRef } from 'react';
import { gsap, TweenMax, Quart } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRankingData } from '../../store/userSlice'
function CommunityPage() {

    const dispatch = useDispatch();
    const rankingData = useSelector(state => state.user.rankingData);

    useEffect(() => {
        dispatch(fetchRankingData());
    }, [dispatch]);

    let top3 = [];
    let others = [];

    if (rankingData) {
        const sortedRanking = [...rankingData].sort((a, b) => b.membersite_amount - a.membersite_amount);
        top3 = sortedRanking.slice(0, 3);
        others = sortedRanking.slice(3);
    }


    const countdownRef = useRef(null);

    useEffect(() => {
        Countdown.init(countdownRef.current);
    }, []);
    return (
        <div>
            <div className='commmunity_content'>
                <div className="community_left_content">
                    <div className='commmunity_header' >
                        <div className='ranking_cup'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/ranking_cup.svg'} alt='' />
                        </div>
                        <div className='ranking_detail'>
                            <div className='ranking_detail_border'>

                                <span>The weekly leaderboard will reward $15 per week, Any task that you complete immediately counts, no matter if it is a survey, an offer or held back at the moment.!</span>
                            </div>

                        </div>
                        <div className='ranking_couter_time'>
                            <div className="wrap">

                                <div className="countdown" ref={countdownRef}>
                                    <div className="bloc-time hours" data-init-value="24">
                                        <span className="count-title">Hours</span>

                                        <div className="figure hours hours-1">
                                            <span className="top">2</span>
                                            <span className="top-back">
                                                <span>2</span>
                                            </span>
                                            <span className="bottom">2</span>
                                            <span className="bottom-back">
                                                <span>2</span>
                                            </span>
                                        </div>

                                        <div className="figure hours hours-2">
                                            <span className="top">4</span>
                                            <span className="top-back">
                                                <span>4</span>
                                            </span>
                                            <span className="bottom">4</span>
                                            <span className="bottom-back">
                                                <span>4</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="bloc-time min" data-init-value="0">
                                        <span className="count-title">Minutes</span>

                                        <div className="figure min min-1">
                                            <span className="top">0</span>
                                            <span className="top-back">
                                                <span>0</span>
                                            </span>
                                            <span className="bottom">0</span>
                                            <span className="bottom-back">
                                                <span>0</span>
                                            </span>
                                        </div>

                                        <div className="figure min min-2">
                                            <span className="top">0</span>
                                            <span className="top-back">
                                                <span>0</span>
                                            </span>
                                            <span className="bottom">0</span>
                                            <span className="bottom-back">
                                                <span>0</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="bloc-time sec" data-init-value="0">
                                        <span className="count-title">Seconds</span>

                                        <div className="figure sec sec-1">
                                            <span className="top">0</span>
                                            <span className="top-back">
                                                <span>0</span>
                                            </span>
                                            <span className="bottom">0</span>
                                            <span className="bottom-back">
                                                <span>0</span>
                                            </span>
                                        </div>

                                        <div className="figure sec sec-2">
                                            <span className="top">0</span>
                                            <span className="top-back">
                                                <span>0</span>
                                            </span>
                                            <span className="bottom">0</span>
                                            <span className="bottom-back">
                                                <span>0</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="community_right_content">
                    <div className='ranking_status'>
                        <div className='ranking'>
                            <div className='ranking_image'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/rank2.png'} alt='rank2' />
                            </div>
                            <div className='ranking_image'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/rank1.png'} alt='rank1' />
                            </div>
                            <div className='ranking_image'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/rank3.png'} alt='rank3' />
                            </div>
                        </div>
                        <div className='bottom_rank'>
                            <div className={`bottom_rank2`}>
                                <span>{top3[1]?.membersite_name}</span>

                                <div>
                                    <img src="/assets/images/buzz_coin.svg" alt="" />
                                    <span>{top3[1]?.membersite_amount}</span>
                                </div>
                            </div>
                            <div className={`bottom_rank1`}>
                                <span>{top3[0]?.membersite_name}</span>
                                <div>
                                    <img src="/assets/images/buzz_coin.svg" alt="" />
                                    <span>{top3[0]?.membersite_amount}</span>
                                </div>
                            </div>
                            <div className={`bottom_rank3`}>
                                <span>{top3[2]?.membersite_name}</span>
                                <div>
                                    <img src="/assets/images/buzz_coin.svg" alt="" />
                                    <span>{top3[2]?.membersite_amount}</span>
                                </div>
                            </div>
                        </div>
                        <div className='rank_text'>
                            <div className={`rank_text_title 2nd`}>
                                <img src="/assets/images/buzz_coin.svg" alt="" />

                                <span>{top3[1]?.membersite_rewards}</span>
                            </div>
                            <div className={`rank_text_title 1st`}>
                                <img src="/assets/images/buzz_coin.svg" alt="" />
                                <span>{top3[0]?.membersite_rewards}</span>
                            </div>
                            <div className={`rank_text_title 3rd`}>
                                <img src="/assets/images/buzz_coin.svg" alt="" />
                                <span>{top3[2]?.membersite_rewards}</span>
                            </div>
                        </div>

                    </div>
                    <div className='ranking_table'>
                        <div class="table">
                            <div class="table-header">
                                <div class="header__item"><a id="name" class="filter__link" href="#">Rank</a></div>
                                <div class="header__item"><a id="wins" class="filter__link filter__link--number" href="#">User Name</a></div>
                                <div class="header__item"><a id="draws" class="filter__link filter__link--number" href="#">Earning</a></div>
                                <div class="header__item"><a id="losses" class="filter__link filter__link--number" href="#">Rewards</a></div>

                            </div>
                            <div className="table-content">
                                {others.map((user, index) => (
                                    <div className="table-row" key={index + 4}>
                                        <div className="table-data">{index + 4}</div>
                                        <div className="table-data">{user.membersite_name}</div>
                                        <div className="table-data">
                                            <img src="/assets/images/buzz_coin.svg" alt="" />
                                            <span>{user.membersite_amount}</span>
                                        </div>
                                        <div className="table-data">
                                            <img src="/assets/images/buzz_coin.svg" alt="" />
                                            <span>{user.membersite_rewards}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default CommunityPage;

const Countdown = {
    $el: null,
    countdown_interval: null,
    total_seconds: 0,

    init: function (element) {
        // Dừng bộ đếm thời gian cũ
        if (this.countdown_interval) {
            clearInterval(this.countdown_interval);
        }

        this.$el = element;
        this.$ = {
            hours: this.$el.querySelectorAll(".bloc-time.hours .figure"),
            minutes: this.$el.querySelectorAll(".bloc-time.min .figure"),
            seconds: this.$el.querySelectorAll(".bloc-time.sec .figure")
        };

        let now = new Date();
        let nextSunday = new Date(now);
        nextSunday.setDate(now.getDate() + (7 - now.getDay()) % 7);
        nextSunday.setHours(24, 0, 0, 0);

        this.total_seconds = Math.floor((nextSunday - now) / 1000);

        this.values = {
            hours: Math.floor(this.total_seconds / 3600),
            minutes: Math.floor((this.total_seconds % 3600) / 60),
            seconds: this.total_seconds % 60
        };

        // Cập nhật giờ, phút, và giây trước khi bắt đầu đếm
        this.checkHour(this.values.hours, this.$.hours[0], this.$.hours[1]);
        this.checkHour(this.values.minutes, this.$.minutes[0], this.$.minutes[1]);
        this.checkHour(this.values.seconds, this.$.seconds[0], this.$.seconds[1]);

        this.count();
    },

    count: function () {
        const that = this;
        this.countdown_interval = setInterval(function () {
            if (that.total_seconds > 0) {
                if (that.values.seconds > 0) {
                    --that.values.seconds;
                    that.checkHour(that.values.seconds, that.$.seconds[0], that.$.seconds[1]);
                }

                if (that.values.seconds === 0 && that.values.minutes > 0) {
                    that.values.seconds = 59;
                    --that.values.minutes;
                    that.checkHour(that.values.seconds, that.$.seconds[0], that.$.seconds[1]);
                    that.checkHour(that.values.minutes, that.$.minutes[0], that.$.minutes[1]);
                }

                if (that.values.minutes === 0 && that.values.hours > 0 && that.values.seconds === 0) {
                    that.values.minutes = 59;
                    --that.values.hours;
                    that.checkHour(that.values.minutes, that.$.minutes[0], that.$.minutes[1]);
                    that.checkHour(that.values.hours, that.$.hours[0], that.$.hours[1]);
                }

                --that.total_seconds;
            } else {
                clearInterval(that.countdown_interval);
            }
        }, 1000);
    },

    animateFigure: function ($el, value) {
        const $top = $el.querySelector(".top");
        const $bottom = $el.querySelector(".bottom");
        const $back_top = $el.querySelector(".top-back");
        const $back_bottom = $el.querySelector(".bottom-back");

        $back_top.querySelector("span").textContent = value;
        $back_bottom.querySelector("span").textContent = value;

        gsap.to($top, 0.8, {
            rotationX: '-180deg',
            transformPerspective: 300,
            ease: Quart.easeOut,
            onComplete: function () {
                $top.textContent = value;
                $bottom.textContent = value;
                gsap.set($top, { rotationX: 0 });
            }
        });

        gsap.to($back_top, 0.8, {
            rotationX: 0,
            transformPerspective: 300,
            ease: Quart.easeOut,
            clearProps: 'all'
        });
    },


    checkHour: function (value, $el_1, $el_2) {
        const val_1 = Math.floor(value / 10);
        const val_2 = value % 10;

        const currentVal_1 = parseInt($el_1.querySelector(".top").textContent, 10);
        const currentVal_2 = parseInt($el_2.querySelector(".top").textContent, 10);

        if (currentVal_1 !== val_1) {
            this.animateFigure($el_1, val_1);
        }
        if (currentVal_2 !== val_2) {
            this.animateFigure($el_2, val_2);
        }
    }

};
