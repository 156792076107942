import '../weblead/webapp.css'
import { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



function Weblogin(){

    const [ip, setIp] = useState(''); // State để lưu địa chỉ IP
    const [platform, setPlatform] = useState(''); // State để lưu platform
    const navigate = useNavigate();
    const [membername, setMembername] = useState(''); // State to store the input value
    const [message, setMessage] = useState(''); // State to store the message


    useEffect(() => {
        // Lấy địa chỉ IP từ https://api.ipify.org?format=json
        axios.get('https://api.ipify.org?format=json')
          .then((response) => {
            setIp(response.data.ip);
          })
          .catch((error) => {
            console.error('Error getting IP:', error);
          });
    
      
          const userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.includes('android')) {
            setPlatform('Android');
          } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
            setPlatform('iOS');
          } else {
            setPlatform('Desktop');
          }
        }, []);


    useEffect(() => {
        const member_id = localStorage.getItem('member_id');
    
        if (member_id) {
          navigate('/weblead');
        }
      }, []);

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Create an object with the data to send
        const requestData = {
          member_name: membername,
          ip: ip, // Replace with the actual IP
          platform: platform, // Replace with the actual platform
        };
    
        try {
            const response = await axios.post('https://app.cashbuzz.net/api/weblogin', requestData);
      
            if (response.status === 200) {
              setMessage('Login successful');
              localStorage.setItem('member_id', response.data);
              navigate('/weblead');

            } else if (response.status === 400) {
              setMessage('Error: ' + response.data); // Display the specific error message from the API
            } else {
              setMessage('Login failed');
            }
          } catch (error) {
            if (error.response && error.response.status === 400) {
              setMessage('Error: ' + error.response.data); // Handle the specific error message from the API response
            } else {
              setMessage('Error: ' + error.message);
            }
          }
        };
      
        const handleInputChange = (e) => {
          setMembername(e.target.value);
        };
    

    return(
        <div className='webbody'>
       <div className="mainheader">
            <div className="topheader">
                <div className="left-menu-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <a className="logo">
                <img src="/assets/images/topbar_logo.png" alt="" />
                </a>

             
            </div>

        </div>
        <div className="webhome-page">
        <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="form-content">

               
                    <div className="signup-container" >
                        <div className="title">Member login</div>
                        <div className="description">Start <span>earning</span> today!</div>
                        <div className="signup-content signup-form">
                            <div className="signup-input">
                                <label  className="active">Username / Email</label>
                                <input 
                                  name="membername"
                                  id="membername"
                                  placeholder=""
                                  className="username"
                                  value={membername}
                                  onChange={(e) => setMembername(e.target.value)}
                                />
                            </div>
                            
                            <button type="submit" className="register-btn">LOGIN</button>


                        </div>
                        {message && <div className="message">{message}</div>}
                    </div>
              
            </div>
         </form>
        </div>
      
        </div>
    )
}

export default Weblogin;