import '../weblead/webapp.css'
import { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';


function Weblead(){
    const navigate = useNavigate();


    useEffect(() => {
        const memberId = localStorage.getItem('member_id');
        if (!memberId) {
            navigate('/weblogin');
        }
    }, []);

    const modalwall = (wallName) => {
        const memberId = localStorage.getItem('member_id') || '';
        let iframeHtml = '';
    
        switch(wallName.toLowerCase()) {

            case "adgatemedia":
                iframeHtml = `<iframe src="https://wall.adgaterewards.com/nauZp20/${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"> </iframe>`;
                break;
            case 'lootably' :
                iframeHtml = `<iframe src="https://wall.lootably.com/?placementID=clr5agw0q098001076s8x1qzx&sid=${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                break;
            case 'mmwall':
                iframeHtml = `<iframe src="https://wall.make-money.top/?p=644&u=${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                    break;    
            case 'notik':
                iframeHtml = `<iframe src="https://notik.me/coins?api_key=6NhoZqnXhqgzKQsR2yOIa6xjkQkBImJ7&pub_id=l6EiBj&app_id=3pybvztSsp&user_id=${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                break;
            case 'cpalead':
                iframeHtml = `<iframe src="https://fastsvr.com/list/517353&subid=${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                break;
            case 'revlum' :
                iframeHtml = `<iframe src="https://revlum.com/offerwall/o6zbt7tfmekrjlqqsfqw8xy8z0q0k6/${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                break;
            case 'torox' :
                    iframeHtml = `<iframe src="https://torox.io/ifr/show/30469/${memberId}/15593/" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                    break;
            case 'adbreak' :
                    iframeHtml = `<iframe src=https://wall.adbreakmedia.com/appid/${memberId}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                    break;                
           
        }
    
        const modal = document.getElementById("WallModal");
        const iframeContainer = document.getElementById("iframe");
        if (modal && iframeContainer) {
            iframeContainer.innerHTML = iframeHtml;
            modal.style.display = "block";
            document.body.style.overflow = "hidden";
        }
    
        const closeModal = () => {
            modal.style.display = "none";
            document.body.style.overflow = "visible";
        };
    
        // Close modal logic
        document.getElementById("closeModal").onclick = closeModal;
        window.onclick = (event) => {
            if (event.target === modal) {
                closeModal();
            }
        };
    };

    return(
        <div className='webbody'>
       <div className="mainheader">
            <div className="topheader">
                <div className="left-menu-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <a className="logo">
                <img src="/assets/images/topbar_logo.png" alt="" />
                </a>

             
            </div>

        </div>
        <div className="webhome-page">
        <div className="earnpage">
                <div id = "WallModal" className="webmodal">

                <div className="earnWallModal" >
                    <div className="modal-content">
                        <div className="modalTitle">
                                IP: <span id="ip_address"></span>
                            <a href="#!" className="modal-close waves-effect btn-flat closeModalBtn">
                                <i id="closeModal" className="far fa-times-circle"></i>
                            </a>
                        </div>
                        <div id='iframe' className="earnModalIframeContain">

                        </div>
                    </div>
                </div>

                    </div>

                <div className="earn-page-section">
                    <div className="offerwall-section">
                        <div className="network__list">
                            <div className="item adgate"  onClick={() => modalwall('AdgateMedia')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                    <img src="/assets/weblead/adgatemedia.png"/>
                                    </div>
                                </div>
                                <p className="wallName">AdGate</p>

                            </div>

                             <div className="item adgate"  onClick={() => modalwall('Lootably')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                    <img src="/assets/weblead/lootably.png"/>
                                    </div>
                                </div>
                                <p className="wallName">Lootably</p>

                            </div>

                            <div className="item adgate"  onClick={() => modalwall('Mmwall')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                    <img src="/assets/weblead/mmwall.png"/>
                                    </div>
                                </div>
                                <p className="wallName">MM Wall</p>

                            </div>
                      
                            <div className="item adgate"   onClick={() => modalwall('revlum')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                        <img src="/assets/weblead/revlum.png" />
                                    </div>
                                </div>
                                <p className="wallName">Revlum</p>
                            </div>
                     
                        
                            
                       
                            <div className="item adgate"   onClick={() => modalwall('Notik')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                        <img src="/assets/weblead/notik.png" />
                                    </div>
                                </div>
                                <p className="wallName">NOTIK</p>
                            </div>

                            <div className="item adgate" onClick={() => modalwall('Cpalead')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                    <img src="/assets/weblead/cpalead.png" />
                                    </div>
                                </div>
                                <p className="wallName">Cpalead</p>

                            </div>
                            
                            <div className="item adgate" onClick={() => modalwall('Torox')}>
                                <div className="network_logo">

                                    <div className="earn-box-view-button">
                                        <span>View Offer</span>
                                    </div>
                                    <div className="earn-box-network-button">
                                    <img src="/assets/weblead/torox.png" />
                                    </div>
                                </div>
                                <p className="wallName">Cpalead</p>

                            </div>
                        



                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        </div>
    )
}

export default Weblead;