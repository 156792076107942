import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchOffers } from '../../store/userSlice';
import SignIn from './assets/SignIn';
import OfferAPIModal from './assets/OfferAPIModals';
import { OfferAPISkeleton } from './assets/ContentLoader';
import './style/OffersPage.css';
function OffersPage() {
    const dispatch = useDispatch();
    const allOffers = useSelector(state => state.user.offers);
    const [visibleOffers, setVisibleOffers] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const itemsPerPage = 36;
    const [page, setPage] = useState(1);
    const user = useSelector((state) => state.user.data);
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const [isLoading, setIsLoading] = useState(true); 

    const handleOfferClick = (offer) => {
        if (!isUserLogin) {
            setIsSignInModalOpen(true);
        } else {
            setSelectedOffer(offer);
        }
    };


    useEffect(() => {
        dispatch(fetchOffers()).then(() => {
            setIsLoading(false); // Đánh dấu rằng dữ liệu đã tải xong
        });
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading) {
            // Chỉ cập nhật visibleOffers và hasMore khi dữ liệu đã tải xong
            const end = page * itemsPerPage;
            setVisibleOffers(allOffers.slice(0, end));
            if (end >= allOffers.length) {
                setHasMore(false);
            }
        }
    }, [page, allOffers, isLoading]);

    const fetchMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const NETWORK_IMAGES = {
        1: "brand_adgatemedia.png",
        2: "brand_torox.png",
        3: "brand_lootably.png",
        4: "brand_notik.png"
    };
    const renderPlatformImages = (platforms) => {
        const platformMap = {
            'ios': 'platform_ios.svg',
            'android': 'platform_android.svg',
            'desktop': 'platform_desktop.svg',
            'iphone': 'platform_ios.svg',
            'ipad': 'platform_ios.svg',
            'windows': 'platform_desktop.svg',
            'macos': 'platform_desktop.svg',
        };

        if (platforms === 'all' || platforms === '*') {
            return ['platform_ios.svg', 'platform_android.svg', 'platform_desktop.svg'].map(image => (
                <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
            ));
        }

        const platformArray = platforms.split(',');
        const uniqueImages = new Set();

        platformArray.forEach(platform => {
            const imagePath = platformMap[platform];
            if (imagePath) {
                uniqueImages.add(imagePath);
            }
        });

        return Array.from(uniqueImages).map(image => (
            <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
        ));
    };

  
    const skeletons = Array.from({ length: 36 }, (_, index) => <OfferAPISkeleton key={index} />);


    return (
        <div>
            <OfferAPIModal offer={selectedOffer} onClose={() => setSelectedOffer(null)} />
            <SignIn isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
            <InfiniteScroll
                dataLength={visibleOffers.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={skeletons}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have seen all offers</b>
                    </p>
                }
            >
                <div className="offers-container">
                {visibleOffers.map((offer, index) => (
                     
                    <div key={index} className='offer_items' onClick={() => handleOfferClick(offer)}>
                        
                        <div className='offer_platform'>
                            {renderPlatformImages(offer.offer_platform)}
                        </div>
                        <div className='offer_image'>
                            <img src={offer.offer_image} alt={offer.offer_name} />
                        </div>
                        <div className='offer_names'>
                            <span>{offer.offer_name}</span>
                        </div>
                        <div className='offer_description'>
                            <span>{offer.offer_description}</span>
                        </div>
                        <div className='offer_prize'>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/buzz_coin.svg`} alt='Buzz Coin' />
                            <span>{(offer.offer_payout * 500).toFixed(0)}</span>
                        </div>
                        <div className='offer_network'>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/${NETWORK_IMAGES[offer.offer_network]}`} alt={NETWORK_IMAGES[offer.offer_network].split('.').shift()} />
                        </div>
                    </div>
                    
                ))}
                </div>
                
            </InfiniteScroll>
        </div>
    );
}

export default OffersPage;