import './style/Rewardspage.css'
import Cashsection from './rewardspage/cashsection';
import GiftSection from './rewardspage/giftsection';
import GameSection from './rewardspage/gamesection';
function RewardsPage() {
    return (
        <>
            <div className="cash_section">
                <div className="cash_section_header">
                    <div className="cash_header_title">
                        <span>Withdraw Cash</span>
                    </div>
                    <div className="cash_header_description">
                        <span>Cashout money directly to your account!</span>
                    </div>
                </div>
                <Cashsection />
            </div>
            <div className="gift_section">
                <div className="gift_header">
                    <div className="gift_header_title">
                        <span>Withdraw Giftcards</span>
                    </div>
                    <div className="gift_header_description">
                        <span>Redeem gift cards, premium subscriptions, top-ups, and more</span>
                    </div>
                </div>
                <GiftSection />
            </div>
            <div className="gift_section">
                <div className="gift_header">
                    <div className="gift_header_title">
                        <span>Game Staff</span>
                    </div>
                    <div className="gift_header_description">
                        <span>Withdraw virtual cash for games, skins, V-Bucks, Riot Points and more!</span>
                    </div>
                </div>
                <GameSection />
            </div>
        </>
    )
}
export default RewardsPage;