import React, { useEffect, useState } from 'react';

// Import CSS styles
import '../style/Toast.css';

const Toast = ({ id, message, type, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            onClose(id);
        }, 3000); // 5 seconds

        return () => {
            clearTimeout(timer);
        };
    }, [id, onClose]);

    const handleClose = () => {
        setIsVisible(false);
        onClose(id);
    };

    // Tạo một đối tượng để ánh xạ id thành biểu tượng tương ứng
    const iconMappings = {
        success: <ion-icon name="checkmark-circle"></ion-icon>,
        error: <ion-icon name="alert-circle"></ion-icon>,
        warning: <ion-icon name="warning"></ion-icon>,
        info: <ion-icon name="information-circle"></ion-icon>,
    };

    // Sử dụng id để chọn biểu tượng
    const iconElement = iconMappings[id] || <ion-icon name="information-circle"></ion-icon>;

    return (
        <div className={`toast ${isVisible ? 'show' : 'hide'} ${type}`}>
            <div className='column'> {/* Thêm lớp CSS tương ứng với id */}
                {iconElement}
                <div className='mess'>{message}</div>
            </div>
            <ion-icon name="close" onClick={handleClose}></ion-icon>
        </div>
    );
};

export default Toast;
