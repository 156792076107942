import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo, clearUser } from '../../../store/userSlice';
import axios from 'axios';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ProfileModal from './ProfileModal';


function TopMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSignInOpen, setIsSignInOpen] = useState(false);
    const [isSignUpOpen, setIsSignUpOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isCaretVisible, setIsCaretVisible] = useState(true);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const topMenuRef = useRef(null);


    const offers = useSelector((state) => state.user.offers); // Lấy offers từ Redux store
    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOutsideClick = (e) => {
        if (topMenuRef.current && !topMenuRef.current.contains(e.target)) {
            setIsMenuOpen(false);
        }

        // Nếu người dùng nhấp ngoài `.user-button.expanded`, ẩn nó đi
        const userButtonExpanded = document.querySelector('.user-button.expanded');
        if (userButtonExpanded && !userButtonExpanded.contains(e.target)) {
            setIsExpanded(false);
            setIsCaretVisible(true);
        }
    };

    const handleUserButtonClick = () => {
        setIsExpanded(!isExpanded);
        setIsCaretVisible(false);
    };

    const openSignInModal = () => {
        setIsSignInOpen(true);
    };
    const openSignUpModal = () => {
        setIsSignUpOpen(true);
    };

    const openProfileModal = () => {
        setIsProfileModalOpen(true);
    };

    const closeProfileModal = () => {
        setIsProfileModalOpen(false);
    };

    const handleSignOut = async () => {
        const token = localStorage.getItem('userData');
        if (token) {
            const config = {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(token).access_token}`
                }
            };
            try {
                await axios.post('https://app.cashbuzz.net/signout', null, config);
                dispatch(clearUser());
                localStorage.removeItem('userData');

            } catch (error) {

            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('userData');
        if (token && !user) {
            dispatch(fetchUserInfo());
        }
        window.addEventListener('mousemove', handleOutsideClick);
        return () => {
            window.removeEventListener('mousemove', handleOutsideClick);
        };
    }, [user]);

    return (<>
        <div className='topmenu' ref={topMenuRef}>
            <div className='topmenu_content'>
                <Link to='/' className='topmenu_logo'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/topmenu_logo.png'} alt='' />
                </Link>
                
                <div className='topmenu_nav'>
                <Link to='/offers' className={`nav_item ${useLocation().pathname === '/offers' ? 'active' : ''}`}>
                        <span>Top Offers</span>
                        <div className='mline'></div>
                        <div className='underline'></div>
                        <div className='topmenu_bonus'>
                        {offers && offers.length > 0 ? `${offers.length}` : 'NEW'}
                        </div>
                    </Link>
                    <Link to='/earn' className={`nav_item ${useLocation().pathname === '/earn' ? 'active' : ''}`}>
                        <span>Earn</span>
                        <div className='mline'></div>
                        <div className='underline'></div>
                    </Link>
                    <Link to='/rewards' className={`nav_item ${useLocation().pathname === '/rewards' ? 'active' : ''}`}>
                        <span>Rewards</span>
                        <div className='mline'></div>
                        <div className='underline'></div>
                    </Link>
                    <Link to='/community' className={`nav_item ${useLocation().pathname === '/community' ? 'active' : ''}`}>
                        <span>Community</span>
                        <div className='mline'></div>
                        <div className='underline'></div>
                    </Link>
                    <Link to='/bonus' className={`nav_item ${useLocation().pathname === '/bonus' ? 'active' : ''}`}>
                        <span>Bonus</span>
                        <div className='mline'></div>
                        <div className='underline'></div>
                        
                    </Link>
                
                </div>
                {user ? (
                    <div className="user-box">
                        <div className={`user-avatar ${isExpanded ? 'expanded' : ''}`} onClick={openProfileModal}>
                            <img src={user.avatar || 'api-avatar-url'} alt="User Avatar" className="user-avatar" />
                        </div>
                        <div className={`user-info ${isExpanded ? 'expanded' : ''}`} onClick={openProfileModal}>
                            <div className='user-name'>
                                <span>{user.display_name}</span>
                            </div>
                            <div className='user-coin-box'>
                                <div className='coin-icon'>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                </div>
                                <div className='user-coin'>
                                    <span>{user.user_coins}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`user-button ${isExpanded ? 'expanded' : ''}`} onClick={handleUserButtonClick}>
                            {isCaretVisible && <ion-icon name="caret-forward"></ion-icon>}
                            {isExpanded && (
                                <div className='btn_expan profile' onClick={openProfileModal}>
                                    <span>Profile</span>
                                </div>
                            )}
                            {isExpanded && (
                                <Link to='/earn' className='btn_expan affiliate'>
                                <span>Earning</span>
                            </Link>
                            )}
                            {isExpanded && (
                                 <Link to='/bonus' className='btn_expan affiliate'>
                                 <span>Bonus</span>
                             </Link>
                            )}
                            {isExpanded && (
                                <div className='btn_expan signout' onClick={handleSignOut}>
                                    <span>SIGN OUT</span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    isMenuOpen ? (
                        <div className='button_menu'>
                            <div className='action_auth' onClick={openSignUpModal}>
                                <span>SIGN UP</span>
                            </div>
                            <div className='action_auth' onClick={openSignInModal}>
                                <span>SIGN IN</span>
                            </div>
                        </div>
                    ) : (
                        <div className='button_menu' onClick={handleMenuClick}>
                            <div className='authen' onClick={openSignInModal}>
                                <div><ion-icon name="finger-print"></ion-icon></div>
                                <div className='text'>SIGN IN</div>
                            </div>
                            <div className='dropdown_menu'>
                                <span>MENU</span>
                            </div>
                        </div>
                    )
                )}
                  {isSignInOpen && <SignIn isOpen onClose={() => setIsSignInOpen(false)} openSignUp={openSignUpModal} />}
            {isSignUpOpen && <SignUp isOpen onClose={() => setIsSignUpOpen(false)} openSignIn={openSignInModal} />}
                {isProfileModalOpen && <ProfileModal isOpen={isProfileModalOpen} onClose={closeProfileModal} />}
            </div>
           
        </div>

    </>
    );
}

export default TopMenu;
