import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './component/homepage/HomePage';
import LoadingBar from 'react-top-loading-bar'
import Main from './Main';
import Weblogin from './component/weblead/WebLogin';
import Weblead from './component/weblead/WebLead';
import Webclicker from './component/weblead/Webclicker';
import ClickerFrame from './component/weblead/ClickerFrame';

function App() {
  const loadingBarRef = React.createRef(); // Tạo một biến ref
  return (
    <Router>
      <LoadingBar color="#2AEC7C" height='4px' ref={loadingBarRef} /> {/* Sử dụng biến ref ở đầu trang */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage loadingBarRef={loadingBarRef} />} />
          <Route path="/offers" element={<Main loadingBarRef={loadingBarRef} currentPage="offers" />} />
          <Route path="/earn" element={<Main loadingBarRef={loadingBarRef} currentPage="earn" />} />
          <Route path="/rewards" element={<Main loadingBarRef={loadingBarRef} currentPage="rewards" />} />
          <Route path="/community" element={<Main loadingBarRef={loadingBarRef} currentPage="community" />} />
          <Route path="/bonus" element={<Main loadingBarRef={loadingBarRef} currentPage="bonus" />} />
          <Route path="/weblogin" element={<Weblogin />} />
          <Route path="/weblead" element={<Weblead />} />
          <Route path="/webclicker" element={<Webclicker />} />
          <Route path="/earn/network/:networkName" element={<ClickerFrame />} />
        </Routes>
      </div>
     
    </Router>
  );
}

export default App;
