function PrivacyPage() {



    return(
        <>
        
        </>
    )
}
export default PrivacyPage;