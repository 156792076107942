import SplideEarn from "./earnpage/splide_earn";
import RecommendedNetwork from "./earnpage/recommend_network";
import OfferwallNetwork from "./earnpage/offerwall_network";
import SurveyNetwork from "./earnpage/survey_networks";
import './style/Earnpage.css'


function EarnPage() {
    return (
        <div>
            {/* Recommended Offers Slider */}
            <div className="offers_slider">
                <div className="slider_header">
                    <div className="slider_header_title">
                        <span>Recommended Offers</span>
                    </div>
                    <div className="slider_header_description">
                        <span>Some of the highest-paying surveys recommended for you!</span>
                    </div>
                </div>
                <SplideEarn />
            </div>

            {/* Recommended Offer Walls Section */}
            <div className="recommend_network">
                <div className="networks_header">
                    <div className="networks_header_title">
                        <span>Recommended Offer Walls</span>
                    </div>
                    <div className="networks_header_description">
                        <span>The best offer walls with hundreds of paid surveys.</span>
                    </div>
                </div>
                <RecommendedNetwork />
            </div>

            {/* Offer Walls Section */}
            <div className="all_network">
                <div className="networks_header">
                    <div className="networks_header_title">
                        <span>Offer Walls</span>
                    </div>
                    <div className="networks_header_description">
                        <span>We cooperate only with the best websites and Offer Walls that allow you to make easy money and crypto online! Complete surveys and earn free Money, Gift Cards, Bitcoin, Ethereum, Litecoin, and more!</span>
                    </div>
                </div>
                <OfferwallNetwork />
            </div>

            {/* Surveys Section */}
            <div className="survey_network">
                <div className="networks_header">
                    <div className="networks_header_title">
                        <span>Surveys</span>
                    </div>
                    <div className="networks_header_description">
                        <span>Complete paid surveys and make easy money online. You will be rewarded with coins for each survey you complete. You can exchange coins for cash, crypto, or gift cards! Wait no more, make easy money online!</span>
                    </div>
                </div>
                <SurveyNetwork />
            </div>
        </div>
    );
}

export default EarnPage;
