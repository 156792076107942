import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '../style/Splide-custom.css';
import OfferAPIModal from '../assets/OfferAPIModals';
import SignIn from '../assets/SignIn';
import axios from 'axios';
import { OfferAPISkeleton } from '../assets/ContentLoader';
import { fetchOffers } from '../../../store/userSlice';

function SplideEarn() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const isUserLogin = useSelector(state  => state.user.isUserLogin);
    const offers = useSelector(state => state.user.offers.slice(0, 100));

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const NETWORK_IMAGES = {
        1: "brand_adgatemedia.png",
        2: "brand_torox.png",
        3: "brand_lootably.png",
        4: "brand_notik.png"
    };
    const renderPlatformImages = (platforms) => {
        const platformMap = {
            'ios': 'platform_ios.svg',
            'android': 'platform_android.svg',
            'desktop': 'platform_desktop.svg',
            'iphone': 'platform_ios.svg',
            'ipad': 'platform_ios.svg',
            'windows': 'platform_desktop.svg',
            'macos': 'platform_desktop.svg',
        };

        if (platforms === 'all' || platforms === '*') {
            return ['platform_ios.svg', 'platform_android.svg', 'platform_desktop.svg'].map(image => (
                <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
            ));
        }

        const platformArray = platforms.split(',');
        const uniqueImages = new Set();

        platformArray.forEach(platform => {
            const imagePath = platformMap[platform];
            if (imagePath) {
                uniqueImages.add(imagePath);
            }
        });

        return Array.from(uniqueImages).map(image => (
            <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
        ));
    };

    const handleOfferClick = (offer) => {


        if (!isUserLogin) {

            setIsSignInModalOpen(true);
        } else {

            setSelectedOffer(offer);
        }
    };

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json')
            .then((response) => {
                dispatch(fetchOffers(response.data.ip));
            })
            .catch((error) => {
                console.error("Error fetching IP:", error);
            });
    }, [dispatch]);

    return (
        <div className="slider_content">
            <OfferAPIModal offer={selectedOffer} onClose={() => setSelectedOffer(null)} />
            <SignIn isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />

            <Splide options={{
  perPage: window.innerWidth < 480 ? 3 : window.innerWidth < 1023 ? 5 : window.innerWidth < 1423 ? 10 : 11,
  perMove: 12,
  gap: '1rem',
  pagination: false
}}>
                {offers.length === 0 ? (
                    Array(12).fill().map((_, index) => (
                        <SplideSlide key={`skeleton-${index}`}>
                            <OfferAPISkeleton />
                        </SplideSlide>
                    ))
                ) : (
                    offers.map(offer => (
                        <SplideSlide key={offer.offer_id}>
                            <div className='offer_items' onClick={() => handleOfferClick(offer)}>
                                <div className='offer_platform'>
                                    {renderPlatformImages(offer.offer_platform)}
                                </div>
                                <div className='offer_image'>
                                    <img src={offer.offer_image} alt={offer.offer_name} />
                                </div>
                                <div className='offer_names'>
                                    <span>{offer.offer_name}</span>
                                </div>
                                <div className='offer_description'>
                                    <span>{offer.offer_description}</span>
                                </div>
                                <div className='offer_prize'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/buzz_coin.svg`} alt='Buzz Coin' />
                                    <span>{(offer.offer_payout * 500).toFixed(0)}</span>
                                </div>
                                <div className='offer_network'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/${NETWORK_IMAGES[offer.offer_network]}`} alt={NETWORK_IMAGES[offer.offer_network].split('.').shift()} />
                                </div>
                            </div>
                        </SplideSlide>
                    ))
                )}
            </Splide>
        </div>
    );
}

export default SplideEarn;