import React, { useState } from 'react';
import Toast from '../assets/Toast';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateCoins } from '../../../store/userSlice';

const METHODS = {
    paypal: {
        img: "/assets/images/shop_paypal.png",
        className: "paypal",
        hasCountrySelector: false,
        countries: [], // ví dụ
        payouts: [5, 10, 20, 30, 50, 75, 100, 200]
    },
    amazon: {
        img: "/assets/images/shop_amazon.png",
        className: "amazon",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [5, 10, 25, 50]
    },
    apple: {
        img: "/assets/images/shop_apple.png",
        className: "apple",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [5, 10, 25, 50]
    },
    google: {
        img: "/assets/images/shop_google.png",
        className: "google",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [5, 10, 25, 50]
    },
    steam: {
        img: "/assets/images/shop_steam.png",
        className: "steam",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [10, 20, 50, 100]
    },
    netflix: {
        img: "/assets/images/shop_netflix.png",
        className: "netflix",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [15, 25, 30, 60]
    },
    spotify: {
        img: "/assets/images/shop_spotify.png",
        className: "spotify",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [10, 30, 60, 120]
    },
    nintendo: {
        img: "/assets/images/shop_nintendo.png",
        className: "nintendo",
        hasCountrySelector: true,
        countries: ["US", "GB", "CA", "DE", "AU", "FR", "ES", "IT", "SG", "IN"],
        payouts: [10, 20, 35, 50]
    },
    roblox: {
        img: "/assets/images/shop_roblox.png",
        className: "roblox",
        hasCountrySelector: false,
        countries: [],
        payouts: [10, 25, 50, 75, 100]
    },

};


function GiftWithdraw({ isOpen, onClose, selectedMethod }) {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activePayout, setActivePayout] = useState(null);
    const [toasts, setToasts] = useState([]);
    const methodData = METHODS[selectedMethod];
    const [isProcessing, setIsProcessing] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);


    const handlePayoutClick = (value) => {
        setActivePayout(value);
    };
    const handleWithdraw = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const accessToken = userData ? userData.access_token : null;

        if (!accessToken) {

            showToast('error', 'User not authenticated.');
            return;
        }
        if (!activePayout) {
            showToast('error', 'Please select a payout before withdrawing.');
            return;
        }

        if (methodData.hasCountrySelector && !selectedCountry) {
            showToast('error', 'Please select a country before withdrawing.');
            return;
        }


        setIsProcessing(true);
        setTimeout(async () => {
            const requestData = {
                totalcoin: coinPrice,
                method: `${selectedMethod} - $${activePayout}`
            };


            try {
                const response = await axios.post('https://app.cashbuzz.net/api/withdraw/', JSON.stringify(requestData), {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.data.message) {
                    showToast('success', response.data.message);
                    dispatch(updateCoins(user.user_coins - coinPrice));
                } else if (response.data.error) {
                    showToast('error', response.data.error);
                } else {
                    showToast('error', 'Unknown error occurred.');
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    showToast('error', error.response.data.error);
                } else {
                    showToast('error', error.message || 'Error during withdrawal.');
                }
            }
            setIsProcessing(false);
        }, 3000);
    };



    const showToast = (type, message) => {
        clearToasts();
        const id = Date.now();
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };
    const clearToasts = () => {
        setToasts([]);
    };




    const fee = activePayout ? activePayout * 1000 * 0.05 : 0;
    const coinPrice = activePayout ? activePayout * 1000 : 0;

    if (!isOpen || !methodData) return null;

    return (
        <div className="gift-modal-overlay">
            <div className="gift-modal">
                <div className="gift-modal-header">
                    <div>{selectedMethod} - Withdraw</div>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className='gift-modal-content'>
                    <div className='balance'>
                        <div className='balance_text'>Balance: </div>
                        <div className='balance_coins'>
                            <img src="/assets/images/buzz_coin.svg" alt="" />
                            <span>{user.user_coins}</span>
                        </div>
                    </div>
                    <div className='gift-modal-warning'>You will receive an Gift card for your selected country, converted from USD to your local currency.</div>
                    {methodData.hasCountrySelector && (
                        <div className="select_local">
                            <button id="countryDropdown" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                {selectedCountry ? selectedCountry : "Select Country"}
                            </button>
                            {isDropdownOpen && (
                                <div id="countryList" className={`dropdown-content ${isDropdownOpen ? 'show-dropdown' : ''}`}>
                                    {methodData.countries.map(country => (
                                        <a
                                            href="#"
                                            key={country}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedCountry(country);
                                                setIsDropdownOpen(false);
                                            }}
                                        >
                                            <img src={`https://flagsapi.com/${country}/shiny/64.png`} alt={`${country} Flag`} />
                                            {country}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <div className={`payout_list`}>
                        {methodData.payouts.map((value, index) => (
                            <div
                                key={index}
                                className={`card_payout ${methodData.className} ${activePayout === value ? 'active' : ''}`}
                                onClick={() => handlePayoutClick(value)}
                            >
                                <div className='payout_numb'>
                                    ${value}
                                </div>
                                <img src={methodData.img} alt="" />
                            </div>
                        ))}
                    </div>
                    <div className='fee'>
                        <div>Fee</div>
                        <div className='fee_coins'>
                            <img src="/assets/images/buzz_coin.svg" alt="" />
                            <span>{fee}</span>
                        </div>
                    </div>
                    <div className='payout_prize'>
                        <div className='prize_text'>
                            Coin Price
                        </div>
                        <div className='payout_prize_numb'>
                            <img src="/assets/images/buzz_coin.svg" alt="" />
                            <span>{coinPrice}</span>
                        </div>
                    </div>
                    <div className='btn_withdraw' onClick={handleWithdraw}>
                        {isProcessing ? 'Processing...' : 'Withdraw'}
                    </div>
                </div>
            </div>
            <div className="notifications">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
        </div>
    );
}

export default GiftWithdraw;