import React, { useState } from 'react';
import '../style/MobileNav.css';
import { Link, useLocation } from 'react-router-dom';
function MobileNav() {
  const location = useLocation();
  // Sử dụng useState để quản lý trạng thái "active"
  // Hàm xử lý khi mục được nhấp vào


  return (
    <nav>
      <ul>
        <li className={location.pathname === '/' ? 'active' : ''}>
          <Link to="/">
          <ion-icon name="home"></ion-icon>
            <span className="navtitle">Home</span>
          </Link>
        </li>
        <li className={location.pathname === '/offers' ? 'active' : ''}>
          <Link to="/offers">
            <ion-icon name="pricetags"></ion-icon>
            <span className="navtitle">Offers</span>
          </Link>
        </li>
        <li className={location.pathname === '/earn' ? 'active' : ''}>
          <Link to="/earn">
            <ion-icon name="rocket"></ion-icon>
            <span className="navtitle">Earn</span>
          </Link>
        </li>
        <li className={location.pathname === '/rewards' ? 'active' : ''}>
          <Link to="/rewards">
            <ion-icon name="bag-handle"></ion-icon>
            <span className="navtitle">Rewards</span>
          </Link>
        </li>
        <li className={location.pathname === '/community' ? 'active' : ''}>
          <Link to="/community">
            <ion-icon name="ribbon"></ion-icon>
            <span className="navtitle">Community</span>
          </Link>
        </li>
        <li className={location.pathname === '/bonus' ? 'active' : ''}>
          <Link to="/bonus">
            <ion-icon name="gift-sharp"></ion-icon>
            <span className="navtitle">Bonus</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default MobileNav;