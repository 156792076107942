import React, { useState, useEffect } from 'react';
import '../style/Auth.css';
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '../../../store/userSlice'; // Điều chỉnh đường dẫn dựa trên cấu trúc dự án của bạn

const clientId = "559034152528-odrhc4rr493o7gjogbplkrsn4r8i5k49.apps.googleusercontent.com";

function SignUp({ isOpen, onClose , openSignIn }) {
    const [email, setEmail] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const handleSignInClick = () => {
        // Close the SignUp modal
        onClose();

        // Open the SignIn modal
        openSignIn();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            const response = await axios.post('https://app.cashbuzz.net/api/signup', {
                ipAddress,
                email,
                password1,
                password2,
            });

            if (response.data.access_token) {
                localStorage.setItem('userData', JSON.stringify(response.data));
                dispatch(fetchUserInfo());
                onClose();

            } else {
                setErrorMessage(response.data.error);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage('Error occurred during registration. Please try again.');
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        try {
            const googleResponse = await axios.post('https://app.cashbuzz.net/api/google-login', {
                tokenId: response.tokenId,
                ipAddress,
            });

            if (googleResponse.data.access_token) {
                localStorage.setItem('userData', JSON.stringify(googleResponse.data));
                dispatch(fetchUserInfo());
                onClose();

            } else {
                setErrorMessage(googleResponse.data.error);
            }
        } catch (error) {
            setErrorMessage('Google login failed.');
        }
    };

    const handleGoogleFailure = (error) => {
        setErrorMessage('Google login failed.');
    };

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json')
            .then((response) => {
                setIpAddress(response.data.ip);
            })
            .catch((error) => {
                // Xử lý lỗi ở đây nếu cần
            });
    }, []);

    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close-button" onClick={onClose}>&times;</span>

                <form className="form" onSubmit={handleSubmit}>
                    <div className="flex-column">
                        <label>Email</label>
                    </div>
                    <div className="inputForm">
                        <svg height="20" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg">
                            {/* ... (Email icon) */}
                        </svg>
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex-column">
                        <label>Password</label>
                    </div>
                    <div className="inputForm">
                        <svg height="20" viewBox="-64 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg">
                            {/* ... (Password icon) */}
                        </svg>
                        <input
                            type="password"
                            className="input"
                            placeholder="Enter your Password"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex-column">
                        <label>Confirm Password</label>
                    </div>
                    <div className="inputForm">
                        <svg height="20" viewBox="-64 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg">
                            {/* ... (Confirm Password icon) */}
                        </svg>
                        <input
                            type="password"
                            className="input"
                            placeholder="Confirm Password"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            required
                        />
                    </div>

                    <button className="button-submit" type="submit">Sign Up</button>

                     <p className="p">Already have an account? <span className="span" onClick={handleSignInClick}>Sign In</span></p>
                    <p className="p line">Or With</p>

                    <div className="flex-row">
                        <GoogleLogin
                            clientId={clientId}
                            buttonText="Login with Google"
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                            render={(renderProps) => (
                                <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    className="btn google"
                                >
                                    <svg version="1.1" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path style={{ fill: '#FBBB00' }} d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256 c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456 C103.821,274.792,107.225,292.797,113.47,309.408z"></path>
                                        <path style={{ fill: '#518EF8' }} d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451 c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535 c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"></path>
                                        <path style={{ fill: '#28B446' }} d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512 c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771 c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"></path>
                                        <path style={{ fill: '#F14336' }} d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012 c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0 C318.115,0,375.068,22.126,419.404,58.936z"></path>
                                    </svg>
                                    Login with Google
                                </button>
                            )}>

                        </GoogleLogin>

                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </form>
            </div>
        </div>
    );
}

export default SignUp;
