import React from 'react';

const Level = ({ userExp }) => {
    // Định nghĩa các mốc exp cho từng level
    const levelExpRequirements = [
        1000, 2000, 3000, 5000, 10000, 20000, 40000  // Thêm các mốc khác tại đây
    ];

    // Xác định level hiện tại của người dùng dựa trên exp
    let userLevel = 1;
    for (let i = 0; i < levelExpRequirements.length; i++) {
        if (userExp >= levelExpRequirements[i]) {
            userLevel = i + 2;
        } else {
            break;
        }
    }

    return (
        <div className='user_level'>
            <span>Level {userLevel}</span>
        </div>
    );
};

const Exp = ({ userExp }) => {
    // Định nghĩa các mốc exp cho từng level
    const levelExpRequirements = [
        1000, 2000, 3000, 5000, 10000, 20000, 40000 // Thêm các mốc khác tại đây
    ];

    // Xác định level hiện tại của người dùng dựa trên exp
    let userLevel = 1;
    let nextLevelExpRequirement = 1000; // Mốc exp mặc định là 1000
    for (let i = 0; i < levelExpRequirements.length; i++) {
        if (userExp >= levelExpRequirements[i]) {
            userLevel = i + 1;
            nextLevelExpRequirement = levelExpRequirements[i + 1] || 'Max Level';
        } else {
            break;
        }
    }

    return (
        <div className='exp'>
            <span>Exp: </span>
            <span>
                {userExp}/{nextLevelExpRequirement}
            </span>
        </div>
    );
};

export { Level, Exp };