import React from 'react';
import ContentLoader from 'react-content-loader';

const OfferAPISkeleton = (props) => (
    <ContentLoader
        speed={2}
        width={105}
        height={180}
        viewBox="0 0 105 180"
        backgroundColor="#2a2929"
        foregroundColor="#3a3939"
        {...props}
    >
        <rect x="10" y="10" rx="5" ry="5" width="85" height="80" /> // offer_image
        <rect x="10" y="100" rx="3" ry="3" width="85" height="10" /> // offer_names
        <rect x="10" y="115" rx="3" ry="3" width="85" height="8" />  // offer_description
        <rect x="10" y="130" rx="3" ry="3" width="85" height="15" />  // offer_prize
    </ContentLoader>
)




export { OfferAPISkeleton };
