import MobileNav from "./MobileNav";
function Footter() {
    return (
        <div className="footer">
  
            <div className="quicklink">
                <div className="quicklink_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/topbar_logo.png"} alt='' />
                </div>
                <div className="quicklink_menu">
                  
                    <div className="link">
                        <span>Terms of Service</span>
                    </div>
                    <div className="link">
                        <span>Privacy</span>
                    </div>
                  
                    <div className="link">
                        <span>FAQ</span>
                    </div>
                    <div className="sub_link">
                <div className="sublink_item">
                    <div className="item_icon">
                        <ion-icon name="mail"></ion-icon>
                    </div>
                    <div className="item_detail">
                        <div className="detail_title">
                              <a href="mailto:contact@cashbuzz.net">contact@cashbuzz.net</a>
                        </div>
                        <div className="detail_des">
                            <span>Business inquiries</span>
                        </div>
                    </div>
                </div>
            </div>
                  

                </div>
            </div>
     
           
            <div className="address">
                <div className="sublink_item">
                    <div className="item_icon">
                        <ion-icon name="location"></ion-icon>
                    </div>
                    <div className="item_detail">
                        <div className="detail_title">
                            <span>86-90 Paul Street 3Rd Floor</span>
                        </div>
                        <div className="detail_des">
                            <span>London, England C2A 4NE.</span>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <span>©2024 Cashbuzz.net - All rights reserved.</span>
                </div>
                
            </div>
        
           <MobileNav />
        </div>
        
    )
}
export default Footter;