import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Toast from '../assets/Toast';
import CryptoWithdraw from '../assets/CryptoWithdraw';

function Cashsection() {
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMethod, setselectedMethod] = useState(null);
    const [toasts, setToasts] = useState([]);

    const openModal = (method) => {
        setselectedMethod(method);

        if (!isUserLogin) {
            showToast('error', 'To proceed with this action, you are required to log in.');
        } else {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };
    return (
        <>
            <div className="withdraw_cash_list">
                <div className="method_list">

                    <div className="card_method bitcoin" onClick={() => openModal('bitcoin')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_bitcoin.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method eth" onClick={() => openModal('ethereum')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_eth.png"} alt='' />
                        </div>
                    </div>
                    <div className="card_method ltc" onClick={() => openModal('litecoin')}>
                        <div className="card_method_logo">
                            <img src={process.env.PUBLIC_URL + "/assets/images/shop_ltc.png"} alt='' />
                        </div>
                    </div>
                </div>
            </div>


            <div className="notifications">
                {toasts.map((toast, index) => (
                    <Toast
                        key={index}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>


            <CryptoWithdraw isOpen={isModalOpen} onClose={closeModal} selectedMethod={selectedMethod} />
        </>
    );
}

export default Cashsection;
