import './App.css'
import './component/dashboard/style/Animations.css';
import './Main.css'
import './component/dashboard/style/responsive/mobile.css';
import './component/dashboard/style/responsive/tablet.css';
import './component/dashboard/style/responsive/desktop.css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopMenu from './component/dashboard/assets/Topmenu';
import EarnPage from './component/dashboard/EarnPage';
import RewardsPage from './component/dashboard/RewardsPage';
import CommunityPage from './component/dashboard/CommunityPage';
import TimeLine from './component/dashboard/assets/Timeline';
import Footter from './component/dashboard/assets/Footter';
import BonusPage from './component/dashboard/BonusPage';
import OffersPage from './component/dashboard/OffersPage';
import TermsPage from './component/dashboard/TermsPage';
import PrivacyPage from './component/dashboard/PrivacyPage';
import FAQPage from './component/dashboard/FAQPage';


function Main({ currentPage, loadingBarRef }) {
    // LOADINGBAR--------------------------------------------------------------------------
    const location = useLocation();
    // Hiển thị thanh tiến trình
    useEffect(() => {
        loadingBarRef.current.continuousStart();

        // Thực hiện công việc của bạn ở đây...

        // Tắt thanh tiến trình khi công việc hoàn tất
        loadingBarRef.current.complete();
    }, [loadingBarRef, location.pathname]);

    // LOADINGBAR--------------------------------------------------------------------------    



    // DARKMODE--------------------------------------------------------------------------
    // Lấy giá trị từ localStorage và kiểm tra nếu nó tồn tại
    const storedDarkMode = localStorage.getItem('darkMode');
    const initialDarkMode = storedDarkMode ? JSON.parse(storedDarkMode) : false;

    const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);

    // Hàm xử lý khi checkbox thay đổi
    const handleDarkModeChange = () => {
        setIsDarkMode(!isDarkMode); // Đảo ngược trạng thái
    };

    // Lưu trạng thái dark mode vào localStorage khi nó thay đổi
    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    }, [isDarkMode]);
    // DARKMODE--------------------------------------------------------------------------
    return (
        <div className={`main ${isDarkMode ? 'dark' : ''}`}>
             
            <div className='topbar'>
                <div className='topbar-left'>
                    <a href='/' className='topbar_logo'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/topbar_logo.png"} alt='' />
                    </a>
                </div>
                <div className='topbar-right'>
                    <div className='topbar_switch'>
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox"
                            onChange={handleDarkModeChange}
                            checked={isDarkMode}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                            <i className="fas fa-sun"></i>
                            <i className="fas fa-moon"></i>
                            <span className="ball"></span>
                        </label>
                    </div>
                    <div className='topbar_action'>
                        <a href='/earn' className='btn_action'>
                            <span>GET BUZZ NOW</span>
                        </a>
                    </div>
                    
                </div>
               
            </div>

            <div className={`app ${isDarkMode ? 'dark' : ''}`}>
                <div className='app_header'>
               
                    <TopMenu />
                   
                    <TimeLine />
                </div>
                <div className={`app_page ${isDarkMode ? 'dark' : ''}`}>
                    <div className={`app_content ${isDarkMode ? 'dark' : ''}`}>
                        {currentPage === 'offers' && <OffersPage />}
                        {currentPage === 'earn' && <EarnPage />}
                        {currentPage === 'rewards' && <RewardsPage />}
                        {currentPage === 'community' && <CommunityPage />}
                        {currentPage === 'bonus' && <BonusPage />}
                        {currentPage === 'terms' && <TermsPage />}
                        {currentPage === 'privacy' && <PrivacyPage />}
                        {currentPage === 'faq' && <FAQPage />}

                    </div>
                </div>
                
                <Footter />
            </div>
         
        </div>)
};
export default Main;