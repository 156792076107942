// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import timelineReducer from './timelineSlice';


const store = configureStore({
    reducer: {
        user: userReducer,
        timeline: timelineReducer,
  
    },
});

export default store;