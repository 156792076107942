import React, { useEffect, useState } from 'react';
import '../style/WallModals.css'


function WallModals({ isOpen, onClose, selectedNetwork, selectedUserUid }) {


    const sources = {
        adgatemedia: `https://wall.adgaterewards.com/nauZp20/${selectedUserUid}`,
        hangmyads: `https://offerwall.hangmyads.com/offerwall.php?pubid=4853&subid=${selectedUserUid}&curr=coins&percent=400`,
        lootably: `https://wall.lootably.com/?placementID=clr5agw0q098001076s8x1qzx&sid=${selectedUserUid}`,
        mmwall: `https://wall.make-money.top/?p=644&u=${selectedUserUid}`,
        torox: `https://torox.io/ifr/show/30469/${selectedUserUid}/15593`,
        adbreak: `https://wall.adbreakmedia.com/appid/${selectedUserUid}`,
     
        //OK
        notik: `https://notik.me/coins?api_key=6NhoZqnXhqgzKQsR2yOIa6xjkQkBImJ7&pub_id=l6EiBj&app_id=3pybvztSsp&user_id=${selectedUserUid}`,

        revenue: `https://publishers.revenueuniverse.com/wallresp/659/offers?uid=${selectedUserUid}`,
        bitlabs_offer: ``,
        wannads: `https://wall.wannads.com/wall?apiKey=6352710ba8994594144916&userId=${selectedUserUid}`,
        revlum: `https://revlum.com/offerwall/o6zbt7tfmekrjlqqsfqw8xy8z0q0k6/${selectedUserUid}`,
        monlix: `https://offers.monlix.com/?appid=8500&userid=${selectedUserUid}`,
        adgem: `https://api.adgem.com/v1/wall?appid=24578&playerid=${selectedUserUid}`,

        //OK
        cpalead: `https://fastsvr.com/list/517353&subid=${selectedUserUid}`,

        timewall: `https://timewall.io/users/login?oid=6d756e93d7e8dba1&uid=${selectedUserUid}`,

        bitlabs: ``,
        cpxresearch: `https://offers.cpx-research.com/index.php?app_id=14506&ext_user_id=${selectedUserUid}`,
        inbrain: ``,
        tabresearch: ``,



    };
    const sourceUrl = sources[selectedNetwork];


    if (!isOpen) return null;
    const iframeStyle = {
        width: '100%',
        height: '100%',
        border: 'none',
        borderRadius: '0px',

    };

    return (
        <div>
            <div className='wall-modals-overlay'>
                <div className='wall-modals'>
                    <div className='wall_modal_title'>{selectedNetwork}</div>
                    <div className="close" onClick={onClose}>
                        <ion-icon name="close-circle"></ion-icon>
                    </div>
                    <div className='wall-modal-content'>
                        <div className='loader_screen'>
                            <div className="loader"></div>
                        </div>
                        <div className='iframe-network'>
                            <iframe title="OfferWall" 
                                src={sourceUrl}
                                style={iframeStyle}

                            ></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

export default WallModals;
