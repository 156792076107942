import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchTimelineData = createAsyncThunk(
    'timeline/fetchData',
    async (_, { getState }) => {
        const response = await axios.get('https://app.cashbuzz.net/api/timeline');
        const newData = response.data;

        const currentData = getState().timeline.data;
        const newItems = newData.filter(
            item => !currentData.some(
                currentItem => currentItem.history_timecreated === item.history_timecreated
            )
        );



        return { data: newData, newItems };
    }
);

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: {
        data: [],
        newItems: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTimelineData.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchTimelineData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.newItems = action.payload.newItems;
            })
            .addCase(fetchTimelineData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default timelineSlice.reducer;
