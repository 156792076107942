import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WallModals from './WallModals';
import Toast from '../assets/Toast';

function OfferwallNetwork() {
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const user = useSelector((state) => state.user.data);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [selectedUserUid, setSelectedUserUid] = useState(null);
    const [toasts, setToasts] = useState([]);

    const openModal = (network, userUid) => {
        setSelectedNetwork(network);
        setSelectedUserUid(userUid);

        if (!isUserLogin) {
            showToast('error', 'To proceed with this action, you are required to log in.');
        } else {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };

    return (
        <div className="offerwall_networks">
            <div className="network_list">
                <div className="networks_card" onClick={() => openModal("wannads", user?.user_uid)}>
                    <div className="card_note">
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            {/* <span>(120)</span> */}
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_wannads.png"} alt='' />
                    </div>
                </div>

                <div className="networks_card" onClick={() => openModal("revlum", user?.user_uid)}>
                    <div className="card_note">
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_revlum.png"} alt='' />
                    </div>
                </div>

                {/* <div className="networks_card" onClick={() => openModal("monlix", user?.user_uid)}>
                    <div className="card_note">
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <span>(120)</span>
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_monlix.png"} alt='' />
                    </div>
                </div> */}

                <div className="networks_card" onClick={() => openModal("adgem", user?.user_uid)}>
                    <div className="card_note">
                        <div className="bonus">
                            <span>+ 20%</span>
                        </div>
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            {/* <span>(120)</span> */}
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_adgem.png"} alt='' />
                    </div>
                </div>
                <div className="networks_card" onClick={() => openModal("cpalead", user?.user_uid)}>
                    <div className="card_note">
                        <div className="bonus">
                            <span>+ 20%</span>
                        </div>
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            {/* <span>(120)</span> */}
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpalead.png"} alt='' />
                    </div>
                </div>
                <div className="networks_card" onClick={() => openModal("timewall", user?.user_uid)}>
                    <div className="card_note">
                        {/* <div className="bonus">
                            <span>+ 20%</span>
                        </div> */}
                        <div className="rating">
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                            {/* <span>(120)</span> */}
                        </div>
                    </div>
                    <div className="card_logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_timewall.png"} alt='' />
                    </div>
                </div>
            </div>

            <div className="notifications">
                {toasts.map((toast, index) => (
                    <Toast
                        key={index}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
            <WallModals isOpen={isModalOpen} onClose={closeModal} selectedNetwork={selectedNetwork} selectedUserUid={selectedUserUid} />
        </div>
    )
}

export default OfferwallNetwork;
