function TermsPage() {



    return(
        <>
        
        </>
    )
}
export default TermsPage;