import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WallModals from './WallModals';
import Toast from '../assets/Toast';

function SurveyNetwork() {
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const user = useSelector((state) => state.user.data);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [selectedUserUid, setSelectedUserUid] = useState(null);
    const [toasts, setToasts] = useState([]);

    const openModal = (network, userUid) => {
        setSelectedNetwork(network);
        setSelectedUserUid(userUid);

        if (!isUserLogin) { // Sửa ở đây
            showToast('error', 'To proceed with this action, you are required to log in.');
        } else {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };



    return (<div className="survey_networks">
        <div className="network_list">
            <div className="networks_card" onClick={() => openModal("bitlabs", user?.user_uid)}>
                <div className="card_note">
                    {/* <div className="bonus">
                        <span>+ 20%</span>
                    </div> */}
                    <div className="rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span>(120)</span>
                    </div>

                </div>
                <div className="card_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabs.png"} alt='' />
                </div>

            </div>
            <div className="networks_card" onClick={() => openModal("pollfish", user?.user_uid)}>
                <div className="card_note">
                    {/* <div className="bonus">
                        <span>+ 20%</span>
                    </div> */}
                    <div className="rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span>(120)</span>
                    </div>

                </div>
                <div className="card_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/brand_pollfish.png"} alt='' />
                </div>

            </div>
            <div className="networks_card" onClick={() => openModal("inbrain", user?.user_uid)}>
                <div className="card_note">
                    {/* <div className="bonus">
                        <span>+ 20%</span>
                    </div> */}
                    <div className="rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span>(120)</span>
                    </div>

                </div>
                <div className="card_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/brand_inbrain.png"} alt='' />
                </div>

            </div>
            <div className="networks_card" onClick={() => openModal("cpxresearch", user?.user_uid)}>
                <div className="card_note">
                    {/* <div className="bonus">
                        <span>+ 20%</span>
                    </div> */}
                    <div className="rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span>(120)</span>
                    </div>

                </div>
                <div className="card_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpxresearch.png"} alt='' />
                </div>

            </div>
            <div className="networks_card" onClick={() => openModal("tapresearch", user?.user_uid)}>
                <div className="card_note">
                    {/* <div className="bonus">
                        <span>+ 20%</span>
                    </div> */}
                    <div className="rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span>(120)</span>
                    </div>

                </div>
                <div className="card_logo">
                    <img src={process.env.PUBLIC_URL + "/assets/images/brand_tapresearch.png"} alt='' />
                </div>

            </div>
        </div>

        <div className="notifications">
            {toasts.map((toast, index) => (
                <Toast
                    key={index}
                    id={toast.id}
                    message={toast.message}
                    type={toast.type} // Truyền giá trị type từ toast vào prop type của Toast
                    onClose={removeToast}
                />
            ))}
        </div>
        <WallModals isOpen={isModalOpen} onClose={closeModal} selectedNetwork={selectedNetwork} selectedUserUid={selectedUserUid} />
    </div>)
}

export default SurveyNetwork;