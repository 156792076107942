
import React, { useState, useEffect, useRef } from 'react';
import Typed from 'react-typed';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import Footter from '../dashboard/assets/Footter';
import TopMenu from '../dashboard/assets/Topmenu';

function HomePage({ loadingBarRef }) {
    // Effect cho Button MENU--------------------------------------------------------------------------
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const topMenuRef = useRef(null);
    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOutsideClick = (e) => {
        if (topMenuRef.current && !topMenuRef.current.contains(e.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleOutsideClick);
        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    // Effect cho Button MENU--------------------------------------------------------------------------

    // DARKMODE--------------------------------------------------------------------------
    // Lấy giá trị từ localStorage và kiểm tra nếu nó tồn tại
    const storedDarkMode = localStorage.getItem('darkMode');
    const initialDarkMode = storedDarkMode ? JSON.parse(storedDarkMode) : false;

    const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);

    // Hàm xử lý khi checkbox thay đổi
    const handleDarkModeChange = () => {
        setIsDarkMode(!isDarkMode); // Đảo ngược trạng thái
    };

    // Lưu trạng thái dark mode vào localStorage khi nó thay đổi
    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    }, [isDarkMode]);
    // DARKMODE--------------------------------------------------------------------------


    // LOADINGBAR--------------------------------------------------------------------------
    // Hiển thị thanh tiến trình
    useEffect(() => {
        loadingBarRef.current.continuousStart();

        // Thực hiện công việc của bạn ở đây...

        // Tắt thanh tiến trình khi công việc hoàn tất
        loadingBarRef.current.complete();
    }, [loadingBarRef]);

    // LOADINGBAR--------------------------------------------------------------------------    

    // Countup Animation--------------------------------------------------------------------------
    const [, inView] = useInView({
        triggerOnce: true, // Đảm bảo rằng CountUp chỉ chạy một lần khi phần tử xuất hiện
    });


    useEffect(() => {
        if (inView) {
            animateHomeStats();
        }
    }, [inView]);


    function animateHomeStats() {
        const totalAccStatEls = 0;
        const totalStatEls = 0;
        const dailyStatEls = 0;
        const payoutStatEls = 0;

        // Giả sử giá trị ban đầu là 0 và giá trị cuối cùng là 12345
        const finalTotalAccStat = 12345;
        const finalTotalStat = 67890;
        const finalDailyStat = 54321;
        const finalPayoutStat = 98765;

        try {
            let numAnimTotal = new CountUp(totalAccStatEls, 0, finalTotalAccStat, 0, 2);
            if (!numAnimTotal.error) {
                numAnimTotal.start();
            } else {
                console.log('error');
            }
        } catch (error) {
            console.error(error);
        }

        let numAnimDollars = new CountUp(totalStatEls, 0, finalTotalStat, 0, 2);
        if (!numAnimDollars.error) {
            numAnimDollars.start();
        }

        let numAnimAverage = new CountUp(dailyStatEls, 0, finalDailyStat, 0, 2);
        if (!numAnimAverage.error) {
            numAnimAverage.start();
        }

        let numAnimPayout = new CountUp(payoutStatEls, 0, finalPayoutStat, 0, 2);
        if (!numAnimPayout.error) {
            numAnimPayout.start();
        }
    }
    // Countup Animation--------------------------------------------------------------------------


    // Trustpilot--------------------------------------------------------------------------

    const [currentIndex, setCurrentIndex] = useState(0);

    const reviews = [

        {
            username: 'Kayy Kross',
            userreview: "“Very good survey app to make some extra $$ on, won't get you rich, but it will give you some wiggle room in your budget for the week...”",
            avatar: process.env.PUBLIC_URL + "/assets/images/kayyknoss_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },
        {
            username: 'Jonathan Spurlock',
            userreview: '“Worth the time and always something to keep you busy making money enjoying games and other things such as surveys,hands down,best making money online app...”',
            avatar: process.env.PUBLIC_URL + "/assets/images/jonathan_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },
        {
            username: 'Sallybhing',
            userreview: '“I like cashbuzz because it has fast payout. And there is daily reward bonus for login. I hope freecash will add more of the daily task aside ftom offerwall...”',
            avatar: process.env.PUBLIC_URL + "/assets/images/sallybhing_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },
        {
            username: 'volf woody',
            userreview: '“This is a good earning application. In this you can earn good income by spending some time. In this you can earn good income by spending some time....”',
            avatar: process.env.PUBLIC_URL + "/assets/images/volfwoody_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },
        {
            username: 'franky dhanis',
            userreview: '“Thanks for the great platform is really nice and cool with freecash app...”',
            avatar: process.env.PUBLIC_URL + "/assets/images/franky_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },
        {
            username: 'volf woody',
            userreview: '“This is a good earning application. In this you can earn good income by spending some time. In this you can earn good income by spending some time....”',
            avatar: process.env.PUBLIC_URL + "/assets/images/volfwoody_avatar.png",
            stars: Array(5).fill(process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"),
        },

        // Thêm các đánh giá khác vào đây
    ];
    const goToPreviousReview = () => {
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            setCurrentIndex(previousIndex);
        }
    };

    const goToNextReview = () => {
        const nextIndex = currentIndex + 1;
        if (nextIndex < reviews.length) {
            setCurrentIndex(nextIndex);
        }
    };
    //--------------------------------------------------------------------------------------------------------


    return (
        <div className={`main ${isDarkMode ? 'dark' : ''}`}>
            <div className='topbar' id='whatwedo'>
                <div className='topbar-left'>
                    <div className='topbar_logo'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/topbar_logo.png"} alt='' />
                    </div>
                </div>
                <div className='topbar-right'>
                    <div className='topbar_switch'>
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox"
                            onChange={handleDarkModeChange}
                            checked={isDarkMode}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                            <i className="fas fa-sun"></i>
                            <i className="fas fa-moon"></i>
                            <span className="ball"></span>
                        </label>
                    </div>
                    <div className='topbar_action'>
                        <a href='/earn' className='btn_action'>
                            <span>GET BUZZ NOW</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className={`container ${isDarkMode ? 'dark' : ''}`}>
                <div className={`banner ${isDarkMode ? 'dark' : ''}`} >
                    <TopMenu />
                    <div className='banner_content'>
                        <div className='banner_leftside'>
                            <div className="left_headline">
                                <div className="primary_title">
                                    <span>Get Paid</span>
                                    <div className="marque_reward">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div className="description">-
                                    <Typed
                                        strings={['playing games', 'installing apps', 'taking surveys', 'creating accounts', 'and much more']}
                                        typeSpeed={100}
                                        backSpeed={30}
                                        loop
                                    />

                                </div>
                            </div>
                            <div className='about'>
                                <div className="primary_title">
                                    <span>0h 15m 30s</span>
                                </div>
                                <div className="about_description">
                                    <p>Average time until user makes first cashout</p>
                                    <span>Need your earnings now?</span>
                                    <br></br>
                                    <span>
                                        No problem. You can withdraw them almost
                                        instantly starting at $5,00.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <p>Cashbuzz.net</p>
                                </div>
                            </div>
                            <div className='asktojoin'>
                                <a href='/earn' className='button_earnnow'>
                                    <span>Let's earning now</span>
                                    <ion-icon name="arrow-forward-circle"></ion-icon>
                                </a>
                            </div>
                            <div className='arrow'>
                                <img src={process.env.PUBLIC_URL + "/assets/images/arrow.svg"} alt='' />
                            </div>

                        </div>

                        <div className='banner_middle'>
                            
                        </div>
                        <div className="banner_rightside">
                            <div className="right_headline">
                                <div className="second_title">
                                    <span>Want To Earn Free Cash</span>
                                    <br></br>


                                    <span>Within Minutes?</span>
                                </div>
                                <div className="second_description">
                                    <p>Experience the thrill of earning free cash in minutes.
                                        Our platform offers a variety ofactivities, surveys,
                                        and offers that quickly add up to valuable rewards.</p>
                                    <br></br>

                                    <p>Join now and turn your time into free cash
                                        effortlessly.</p>


                                    <p>Cashbuzz.net</p>

                                </div>
                                <div className='needhelp'>
                                    <a href="/faq" className='button_needhelp'>
                                        <span>NEED HELP</span>
                                    </a>
                                </div>
                                <div className="referral_title">
                                    <div className="first_ref_title">
                                        <span>Refer A Friend</span>
                                    </div>
                                    <div className="second_ref_title">
                                        <span>Get Bonus</span>
                                    </div>
                                    <div className="percent">
                                        10%
                                    </div>
                                </div>
                            </div>
                            <img className='bg_display'  src={process.env.PUBLIC_URL + "/assets/images/bg_display.png"} alt='' />
                        </div>
                    </div>
                    <div className='scroll'>
                        <div className="scroll_container">
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                            <span className="scroll_text">Scroll down</span>
                        </div>
                    </div>
                </div>
                <div className='tutorial'>
                    <div className='howitwork' id='howitwork'>
                        <div className='first_content'>
                            <div className='big_content'>
                                <span>HOW IT WORK</span>
                            </div>
                            <div className='small_content'>
                                <div className='numb'>01</div>
                                <div className='title'>Register on CashBuzz</div>
                                <div className='description'>The first step to start earning money with Cashbuzz is to register for an account.
                                    This process is quick and easy, and you'll be up and running in no time!.
                                    Creating an account takes less than a minute. You can sign in using Google.</div>
                            </div>
                            <div className='small_content'>
                                <div className='numb'>02</div>
                                <div className='title'>Choose an offer</div>
                                <div className='description'>Take your pick from the tasks on the earn page.
                                    We list the best offers from companies who want to advertise their apps,
                                    surveys, and products.</div>
                            </div>
                        </div>
                        <div className='second_content'>
                            <div className='sub_content'>
                                <div className='numb'>03</div>
                                <div className='title'>Withdraw your coins</div>
                                <div className='description'>Once you've earned some coins, you can withdraw them from your
                                    account. We offer a range of payment options, including PayPal,
                                    gift cards, Cryptocurrencies and more!. We have everything you want!</div>
                            </div>
                            <div className='primary_content'>
                                <div className='title'>EASY TO USE</div>
                                <div className='description'>Discover how our platform works to transform your time into tangible rewards.
                                    Simply choose from a range of engaging activities, complete surveys, and explore offers.
                                    As you participate, you'll accumulate points or cash that you can redeem for exciting rewards.
                                    With our straightforward process, you'll be well on your way to earning while enjoying a seamless experience.
                                    Get started today and see the results for yourself</div>
                                <div className='call_action'>
                                    GET STARTED
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="statistics">
                        <div className="statis_head">
                            <div className="statis_head_title">
                                <span>YOU COMPLETE TASKS, AND WE PAY THE CASH!</span>
                            </div>
                            <div className="statis_head_description">
                                <span>Earn instant rewards by completing tasks, watching videos and taking surveys. It's that easy!</span>
                            </div>
                        </div>
                        <div className="statis_analytics">
                            <div className="analytic_items">
                                <div className="title">
                                    <span>Happy Users</span>
                                </div>
                                <div className="numb">
                                    <CountUp end={345480}></CountUp>
                                </div>
                            </div>
                            <div className="analytic_items">
                                <div className="title">
                                    <span>Completed Offers</span>
                                </div>
                                <div className="numb">
                                    <CountUp end={156335}></CountUp>
                                </div>
                            </div>
                            <div className="analytic_items">
                                <div className="title">
                                    <span>USD Earned</span>
                                </div>
                                <div className="numb">
                                    <CountUp end={245348}></CountUp>
                                </div>
                            </div>
                            <div className="analytic_items">
                                <div className="title">
                                    <span>Completed Payouts</span>
                                </div>
                                <div className="numb">
                                    <CountUp end={123178}></CountUp>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyus">
                    <div className="hugerewards">
                        <div className="left_rewards">
                            <div className="gitf_group">
                                <img className='rewardcard_01' src={process.env.PUBLIC_URL + "/assets/images/rewardcard_paypal.png"} alt='' />
                                <img className='rewardcard_02' src={process.env.PUBLIC_URL + "/assets/images/rewardcard_googleplay.png"} alt='' />
                                <img className='rewardcard_03' src={process.env.PUBLIC_URL + "/assets/images/rewardcard_apple.png"} alt='' />
                            </div>
                            <div className="crypto_group">
                                <img className='crypto_01' src={process.env.PUBLIC_URL + "/assets/images/crypto_tag.svg"} alt='' />
                            </div>
                            {/* <div className="tag_group">
                                <img className='tag_01' src={process.env.PUBLIC_URL + "/assets/images/bitcoin_tag.svg"} alt='' />
                                <img className='tag_02' src={process.env.PUBLIC_URL + "/assets/images/litecoin_tag.svg"} alt='' />
                                <img className='tag_03' src={process.env.PUBLIC_URL + "/assets/images/etherium_tag.svg"} alt='' />
                            </div> */}
                        </div>
                        <div className="right_rewards">
                            <div className="title">
                                <span>Huge Withdraw Selection</span>
                            </div>
                            <div className="description">
                                <span>Litecoin and Paypal are our biggest and most popular withdrawal methods, essentially transferring legal tender into your wallet. Spending on your guilty pleasures has never been easier. CryptoCurrencies are now a form of Rewards! Pick from our wide range of Crypto and receive with 0 fees.</span>
                            </div>
                            <div className="type_rewards">
                                <div className="type_items">
                                    <div className="header">
                                        <div className="method">
                                            <span>Paypal</span>
                                        </div>
                                        <div className="space">|</div>
                                    </div>
                                    <div className="detail">
                                        <span>Direct-to-PayPal Transactions – Receive Your Earnings Straight to Your PayPal Account!</span>
                                    </div>
                                </div>
                                <div className="type_items">
                                    <div className="header">
                                        <div className="method">
                                            <span>Crypto</span>
                                        </div>
                                        <div className="space">|</div>
                                    </div>
                                    <div className="detail">
                                        <span>Crypto Convenience – Seamlessly Convert Your Earnings into Cryptocurrency!</span>
                                    </div>
                                </div>
                                <div className="type_items">
                                    <div className="header">
                                        <div className="method">
                                            <span>Gift Cards</span>
                                        </div>
                                        <div className="space">|</div>
                                    </div>
                                    <div className="detail">
                                        <span>Gift Card Rewards – Enjoy Your Earnings through Convenient Gift Cards!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ourpartner">
                        <div className="partner_head">
                            <div className="partner_title">
                                <span>Our partners </span>
                            </div>
                            <div className="partner_description">
                                <span>We support most of the offer-wall and survey providers, ensuring a wide range of opportunities for you to earn. Whether it's completing surveys or engaging with offers, our platform integrates seamlessly with various providers, allowing you to choose from a diverse array of tasks tailored to your preferences. Explore these options and maximize your earning potential today!</span>
                            </div>
                        </div>

                        <div className="marquee-container">
                            <div className="marquee-box">
                                <div className="marquee">
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_adgatemedia.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_oneblock.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_mmwall.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_revu.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_monlix.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_torox.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_lootably.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabs.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_pollfish.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_inbrain.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpxresearch.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_adgatemedia.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_oneblock.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_mmwall.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_revu.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_monlix.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_offertoro.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_lootably.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabs.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_pollfish.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_inbrain.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpxresearch.png"} alt='' />
                                    </div>
                                </div>
                                <div className="marquee-reverse">
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpxresearch.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_inbrain.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_pollfish.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabs.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_lootably.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_offertoro.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_monlix.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_revu.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_mmwall.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_oneblock.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_cpxresearch.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_inbrain.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_pollfish.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabs.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_lootably.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_offertoro.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_monlix.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_revu.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_mmwall.png"} alt='' />
                                    </div>
                                    <div className="marquee_items">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/brand_oneblock.png"} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="trustpilot">
                        <div className="trustpilot_container">
                            <div className="rating">
                                <div className="rating_container">
                                    <div className="rating_header">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_logo.png"} alt='' />
                                    </div>
                                    <div className="rating_detail">
                                        <div className="detail_title">
                                            <span>Excellent</span>
                                        </div>
                                        <div className="star_group">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"} alt='' />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"} alt='' />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"} alt='' />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"} alt='' />
                                            <img src={process.env.PUBLIC_URL + "/assets/images/trustpilot_star.png"} alt='' />
                                        </div>
                                        <div className="detail_numb">
                                            <span>Based on </span><span className="numb">456 reviews</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reviews">
                                <div className="review_slide">
                                    <div className="slide">
                                        {reviews.slice(currentIndex, currentIndex + 3).map((review, index) => (
                                            <div className="review_detail" key={index}>
                                                <div className="items_header">
                                                    <div className="user_avatar">
                                                        <img src={review.avatar} alt='' />
                                                    </div>
                                                    <div className="user_rating">
                                                        {review.stars.map((star, starIndex) => (
                                                            <img src={star} key={starIndex} alt='' />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="items_review">
                                                    <div className="item_review_username">{review.username}</div>
                                                    <div className="item_review_userreview">{review.userreview}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="button_control">
                                    <div className="slide_btn" onClick={goToNextReview}>
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </div>
                                    <div className="slide_btn" onClick={goToPreviousReview}>
                                        <ion-icon name="arrow-back-outline"></ion-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="worldmap">
                    <div className="map_container">
                        <div className="map_header">
                            <div className="map_header_title">
                                <span>Users from all</span>
                                <br></br>
                                <span>over the world</span>
                            </div>
                            <div className="map_header_description">
                                <span>Users from all over the world can join our platform and start earning. Regardless of your location, you can take advantage of our opportunities and rewards. Join our global community today and unlock the potential to earn from anywhere!</span>
                                <p>GET START NOW</p>
                            </div>
                        </div>
                        <div className="isolation">
                            <img src={process.env.PUBLIC_URL + "/assets/images/isolation_map.svg"} alt='' />
                        </div>
                    </div>
                </div>
                <Footter />
            </div>

        </div>
    );
}

export default HomePage;
