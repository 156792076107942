import React, { useState, useEffect } from 'react';
import '../style/CryptoWithdraw.css';
import Toast from '../assets/Toast';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateCoins } from '../../../store/userSlice';

function CryptoWithdraw({ isOpen, onClose, selectedMethod }) {
    const [rate, setRate] = useState(null);
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState(''); // Thêm state cho wallet
    const [toasts, setToasts] = useState([]);
    const fee = amount * 0.02;
    const usdValue = (amount - fee) / 1000;
    const coinPrize = rate ? usdValue / rate : 0;
    const [isProcessing, setIsProcessing] = useState(false);


    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);




    const fetchRate = (method) => {
        let apiUrl = '';
        switch (method) {
            case 'bitcoin':
                apiUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd';
                break;
            case 'ethereum':
                apiUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';
                break;
            case 'litecoin':
                apiUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=litecoin&vs_currencies=usd';
                break;
            default:
                break;
        }

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`API returned status code ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                if (method === 'bitcoin') {
                    setRate(data.bitcoin.usd);
                } else if (method === 'ethereum') {
                    setRate(data.ethereum.usd);
                } else if (method === 'litecoin') {
                    setRate(data.litecoin.usd);
                }
            })
            .catch(error => {

            });
    }

    useEffect(() => {
        fetchRate(selectedMethod);
    }, [selectedMethod]);

    const showToast = (type, message) => {
        const id = Date.now();
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };



    const handleWithdraw = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const accessToken = userData ? userData.access_token : null;

        if (!accessToken) {
            showToast('error', 'User not authenticated.');
            return;
        }
        if (!wallet.trim()) {
            showToast('error', 'Please enter your wallet address.');
            return;
        }
        // Kiểm tra nếu amount chưa được nhập hoặc bằng 0
        if (!amount || amount <= 0) {
            showToast('error', 'Please enter a valid amount.');
            return;
        }
        if (!wallet || amount <= 0) {
            showToast('error', 'Please enter a valid amount.');
            return;
        }

        // Kiểm tra nếu amount nhỏ hơn 5000
        if (amount < 5000) {
            showToast('error', 'Amount must be at least 5000.');
            return;
        }



        setIsProcessing(true);
        setTimeout(async () => {
            const requestData = {
                totalcoin: amount,
                method: `${selectedMethod} - $${coinPrize}`,
                wallet: wallet,
            };


            try {
                const response = await axios.post('https://app.cashbuzz.net/api/withdraw/', JSON.stringify(requestData), {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.data.message) {
                    showToast('success', response.data.message);
                    dispatch(updateCoins(user.user_coins - amount));
                } else if (response.data.error) {
                    showToast('error', response.data.error);
                } else {
                    showToast('error', 'Unknown error occurred.');
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    showToast('error', error.response.data.error);
                } else {
                    showToast('error', error.message || 'Error during withdrawal.');
                }
            }
            setIsProcessing(false);
        }, 3000);
    };



    if (!isOpen) return null;




    return (
        <div className="crypto-overlay">
            <div className="crypto-modal">
                <div className="crypto-header">
                    <div>{selectedMethod} - Withdraw</div>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className="crypto-content">
                    <div className='giftcard_withdraw'>
                        <div className='balance'>
                            <div className='balance_text'>Balance: </div>
                            <div className='balance_coins'>
                                <img
                                    src="/assets/images/buzz_coin.svg"
                                    alt=""
                                />
                                <span>{user.user_coins}</span>
                            </div>
                        </div>
                        <div className="select_local">
                            <div className='select_text'>
                                Your coinbase email address (Your first withdrawal minimum is 700 coins in order to avoid fraudulent activity, after this, the minimum will be 100 coins)
                            </div>
                            <input
                                className="walletCrypto"
                                placeholder={`Enter ${selectedMethod.toUpperCase()} wallet address`}
                                autoComplete="off"
                                type="text"
                                value={wallet} // Thêm value cho input
                                onChange={(e) => setWallet(e.target.value)} // Thêm event handler
                            />
                            <div className='select_text' >
                                Amount
                            </div>
                            <input className="amountCrypto" placeholder="Enter amount" autoComplete="off" type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                name="amount" />
                        </div>
                        <div className='giftcard_withdraw'>
                            <div className='get_rate'>
                                <div className='rate_text'>
                                    Minimum 5000 coins ($5.0)
                                </div>
                                <div className='realtime_rate'>
                                    <span>1 |</span>
                                    <span>${rate ? rate.toFixed(2) : "Loading..."}</span>
                                </div>
                            </div>

                            <div className='fee'>
                                <div>Fee</div>
                                <div className='fee_coins'>
                                    <img
                                        src="/assets/images/buzz_coin.svg"
                                        alt=""
                                    />
                                    <span>{fee}</span>
                                </div>
                            </div>
                            <div className='payout_prize'>
                                <div className='prize_text'>
                                    You receive
                                </div>
                                <div className='payout_prize_numb'>
                                    <span>{coinPrize.toFixed(8)}</span>
                                </div>
                            </div>
                            <div className='btn_withdraw' onClick={handleWithdraw}>
                                {isProcessing ? 'Processing...' : 'Withdraw'}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="notifications">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
        </div>
    );
}

export default CryptoWithdraw;
