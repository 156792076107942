import './style/BonusPage.css';
import React, { useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from './assets/Toast';
function BonusPage() {

    const dispatch = useDispatch();
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const user = useSelector(state => state.user.data);
    const [bonusCode, setBonusCode] = useState('');


    const [timeLeft, setTimeLeft] = useState('');
    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            const difference = tomorrow - now;

            let timeLeft = '';
            if (difference > 0) {
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);
                timeLeft = `${hours}h ${minutes}m ${seconds}s`;
            }
            return timeLeft;
        };

        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleClaimBonus = () => {
        if (!isUserLogin) {
            showToast("Please login to claim your bonus.", "error");
            return;
        }
    
        if (!bonusCode.trim()) {
            showToast("Please enter a bonus code.", "error");
            return;
        }
    
        showToast("Bonus code is invalid, please try again.", "error");
    };

   
    // Tạo liên kết giới thiệu dựa trên trạng thái đăng nhập và mã giới thiệu của người dùng
    const referralLink = isUserLogin && user && user.affilate_code 
        ? `cashbuzz.com/ref/${user.affilate_code}` 
        : "Please login";
    const isLessThan10000Exp = user && user.user_exp < 10000;
    const editButtonClass = isLessThan10000Exp ? 'aff_link_detail_link_edit disabled' : 'aff_link_detail_link_edit';

    const [toasts, setToasts] = useState([]);

    const showToast = (message, type) => {
        const id = Date.now();
        setToasts([...toasts, { id, message, type }]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    const copyReferralLinkToClipboard = () => {
        if (!isUserLogin || !user || !user.affilate_code) {
            showToast("Please login to copy your referral link.", "error");
            return;
        }
    
        const referralLink = `https://cashbuzz.com/ref/${user.affilate_code}`;
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                showToast("Link copied to clipboard!", "success");
            })
            .catch(err => {
                console.error('Error copying link to clipboard', err);
                showToast("Failed to copy link", "error");
            });
    };
    const encodedReferralLink = encodeURIComponent(referralLink);
    const shareLinks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedReferralLink}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodedReferralLink}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedReferralLink}`,
        whatsapp: `https://wa.me/?text=${encodedReferralLink}`
    };

    return(
        <>
        <div className="bonus_content">
            <div className="bonus_left">
               <div className='affiliate_link'>
                    <div className='affiliate_link_left'>
                        <div className='aff_link'>
                            <div className='aff_link_title'>
                            Your Referral Link
                            </div>
                            <div className='aff_link_detail'>
                                <div className='aff_link_detail_link'>
                                {referralLink}
                                </div>
                                <div className={editButtonClass}>
                                    <ion-icon name="cog"></ion-icon>
                                    <span>Edit</span> 
                                </div>
                                <div className='aff_link_detail_link_copy' onClick={copyReferralLinkToClipboard}>
                <ion-icon name="copy"></ion-icon>
                <span>Copy Link</span> 
            </div>
            
                            </div>
                        </div>
                    </div>
                    <div className='affiliate_link_right'>
                        <div className='affiliate_right_title'>Share your referral link</div>
                        <div className='affiliate_right_detail'>
                        <div className="social_card">
            <a className="socialContainer containerOne" href={shareLinks.facebook} target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 16 16" className="socialSvg instagramSvg"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path> </svg>
            </a>
            <a className="socialContainer containerTwo" href={shareLinks.twitter} target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 16 16" className="socialSvg twitterSvg"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path> </svg> 
            </a>
            <a className="socialContainer containerThree" href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 448 512" className="socialSvg linkdinSvg"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
            </a>
            <a className="socialContainer containerFour" href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 16 16" className="socialSvg whatsappSvg"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path> </svg>
            </a>
        </div>            


                        </div>
                    </div>
               </div>
               <div className='daily_rewards'>
                    <div className='daily_rewards_title'>
                        <div className='daily_rewards_title_left'>7 Day Streak Rewards</div>
                        <div className='daily_rewards_title_right'>Earn $1.00 or more within 24 hours to keep your streak</div>
                    </div>
                    <div className='streakbox'>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 1
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>10</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 2
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>30</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 3
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>100</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 4
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>500</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 5
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>1500</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 5
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span>4000</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='box_items'>
                            <div className='box_items_icon'>
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className='box_items_days'>
                                Day 7
                            </div>
                            <div className='box_items_reward'>
                            <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                            <span> up to 20000</span>
                            </div>
                            <div className='box_items_claim'>
                                Claim
                            </div>
                        </div>
                        
                    </div>
                    <div className='daily_info'>
                    <ion-icon name="alert-circle-outline"></ion-icon>
                    <span>Earn $1.00 more today to keep your streak! Time left: </span>
    <span className="time_left">{timeLeft}</span>
                    </div>
               </div>

               <div className='challenges'>
                    <div className='challenges_title'>
                        <div className='challenges_title_left'>Challenges</div>
                        <div className='challenges_title_right'>Complete challenges to earn extra coins.</div>
                    </div>
                    <div className='challengesbox'>
                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                                Complete 1 survey
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>100</span>
                                
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>

                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                            Invite 2 people to join using your unique referral link.
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>200</span>
                               
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                            Join to our community on Discord Server.
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>200</span>
                               
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>

                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                                Complete 5 survey
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>1000</span>
                                
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>
                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                                Complete 10 offer
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>2000</span>
                               
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>





                        <div className='challengesbox_items'>
                            <div className='challengesbox_items_title'>
                                <span>Earn total 10000 buzzcoins</span> 
                            </div>
                            <div className='challengesbox_items_reward'>
                                <span>Reward:</span>
                                <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                <span>1000</span>
                               
                            </div>
                            <div className='challengesbox_items_claim'>
                                Claim
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            <div className="bonus_right">
                <div className='bonus_box'>
                    <div className='bonus_box_title'>
                    Bonus Code
                    </div>
                    <div className='bonus_box_des'>
                    Enter the bonus code received from our social media
                    </div>
                    <div className='inputcode'>
                    <label className="search-label">
                    <input type="text" name="text" className="input" required="" placeholder="Type here..."   value={bonusCode}
                    onChange={(e) => setBonusCode(e.target.value)}/>
                    </label>
                    <button className='btn_bonus' onClick={handleClaimBonus}>Claim Bonues</button>
           
                    </div>
                    
                </div>
            </div>
            
        </div>
        <div className="notifications">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>
        </>
    )
}
export default BonusPage;