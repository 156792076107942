import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchRankingData = createAsyncThunk('ranking/fetchRankingData', async () => {
    const response = await axios.get('https://app.cashbuzz.net/api/ranking/');
    return response.data;
});

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
    const token = localStorage.getItem('userData');
    if (token) {
        const config = {
            headers: {
                'Authorization': `Bearer ${JSON.parse(token).access_token}`
            }
        };
        const response = await axios.get('https://app.cashbuzz.net/api/user-info', config);
        return response.data.user;
    }
    throw new Error('No token found!');
});

export const fetchOffers = createAsyncThunk('user/fetchOffers', async (ipAddress) => {
    const response = await axios.get(`https://app.cashbuzz.net/api/offers/?ip=${ipAddress}`);
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: null,
        isUserLogin: false,
        offers: [],
        rankingData: null,
        rankingStatus: 'idle',
        rankingError: null
    },
    reducers: {
        setUser: (state, action) => {
            state.data = action.payload;
        },
        clearUser: (state) => {
            state.data = null;
            state.isUserLogin = false;
        },
        updateDisplayName: (state, action) => {
            if (state.data) {
                state.data.display_name = action.payload;
            }
        },
        updateCoins: (state, action) => {
            if (state.data) {
                state.data.user_coins = action.payload;
            }
        }
    },
    extraReducers: {
        [fetchUserInfo.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isUserLogin = true;
        },
        [fetchUserInfo.rejected]: (state) => {
            state.isUserLogin = false;
        },
        [fetchRankingData.pending]: (state) => {
            state.rankingStatus = 'loading';
        },
        [fetchRankingData.fulfilled]: (state, action) => {
            state.rankingStatus = 'succeeded';
            state.rankingData = action.payload;
        },
        [fetchRankingData.rejected]: (state, action) => {
            state.rankingStatus = 'failed';
            state.rankingError = action.error.message;
        },
        [fetchOffers.fulfilled]: (state, action) => {
            state.offers = action.payload;
        }
    }
});

export const { setUser, clearUser, updateDisplayName, updateCoins } = userSlice.actions;
export default userSlice.reducer;
