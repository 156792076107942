import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../style/OfferAPIModals.css'

function OfferAPIModal({ offer, onClose }) {

    const dispatch = useDispatch();
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const user = useSelector((state) => state.user.data);


    const NETWORK_IMAGES = {
        1: "brand_adgatemedia.png",
        2: "brand_lootably.png",
        3: "brand_torox.png",
        4: "brand_notik.png",
    };

    const renderPlatformImages = (platforms) => {
        const platformMap = {
            'ios': 'platform_ios.svg',
            'android': 'platform_android.svg',
            'desktop': 'platform_desktop.svg',
            'iphone': 'platform_ios.svg',
            'ipad': 'platform_ios.svg',
            'windows': 'platform_desktop.svg',
            'macos': 'platform_desktop.svg',

        };

        // Nếu platforms là 'all' hoặc '*', hiển thị tất cả 3 hình ảnh
        if (platforms === 'all' || platforms === '*') {
            return ['platform_ios.svg', 'platform_android.svg', 'platform_desktop.svg'].map(image => (
                <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
            ));
        }

        const platformArray = platforms.split(',');
        const uniqueImages = new Set();

        platformArray.forEach(platform => {
            const imagePath = platformMap[platform];
            if (imagePath) {
                uniqueImages.add(imagePath);
            }
        });

        return Array.from(uniqueImages).map(image => (
            <img src={`${process.env.PUBLIC_URL}/assets/images/${image}`} alt={image.split('/').pop()} key={image} />
        ));
    };

    if (!offer) return null; // Nếu không có offer, không hiển thị modal


    return (
        <div className="modalOfferAPI-backdrop">
            <div className="modalOfferAPI-content">

                <div className="modalOfferAPI-close" onClick={onClose}>
                    <ion-icon name="close-circle"></ion-icon>
                </div>
                <div className='modalOfferAPI-header'>

                </div>


                <div className='modalOfferAPI-body '>
                    <div className='main_offerinfo'>
                        <div className='offer_img'>
                            <img src={offer.offer_image} alt={offer.offer_name} />
                        </div>
                        <div className='offer_detail'>
                            <div className='offer_detail_name'>
                                <span>{offer.offer_name}</span>
                            </div>
                            <div className='offer_detail_about'>
                                <div className='detail_network'>
                                    <div className='detail_network_image'>

                                        <img src={`${process.env.PUBLIC_URL}/assets/images/${NETWORK_IMAGES[offer.offer_network]}`} alt="Network Brand" />

                                    </div>
                                    <div className='detail_network_text'>
                                        <span>Offerwall</span>
                                    </div>

                                </div>

                                <div className='offer_detail_platform'>
                                    <div className='detail_platform_image'>
                                        {renderPlatformImages(offer.offer_platform)}
                                    </div>
                                    <div className='detail_platform_text'>
                                        <span>Platform</span>
                                    </div>

                                </div>
                                <div className='offer_detail_payout'>
                                    <div className='detail_payout_image'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/buzz_coin.svg"} alt='' />
                                        <span>{(offer.offer_payout * 500).toFixed(0)}</span>
                                    </div>
                                    <div className='detail_payout_text'>
                                        <span>Reward</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='offer_detail_description'>
                        <div className='detail_description_title'>
                            <span>DESCRIPTION</span>
                        </div>
                        <div className='detail_description_text'>
                            <span>{offer.offer_description}</span>
                        </div>

                    </div>
                    <div className='offer_detail_description'>
                        <div className='detail_description_title'>
                            <span>REQUIREMENTS</span>
                        </div>
                        <div className='detail_description_text'>
                            <span>{offer.offer_requirements}</span>
                        </div>

                    </div>
                    <div className='start_offer'>
                        <a href={`${offer.offer_link}${user.user_uid}`} target='_blank'> Start Offer </a>
                    </div>


                </div>

            </div>
        </div>
    );
}

export default OfferAPIModal;